import { isEqual } from 'lodash'
import Vue from 'vue'

const DashboardUtils = {

  /**
   * @public
   * Shortens a given axes label
   * @param {string} label - Unformatted axes label
   * @returns {string} Shortened axes label with 20 characters + '...' its length is more than 20 characters
   */
  shortenXAxesLabel: function (label = '') {
    if (!label) {
      return ''
    } else {
      label = label.toString()// TODO: long whole numbers will have trailing zeros added. May want to address it when dyanmically determining characterLimit
      const characterLimit = 20 // TODO: to be made dynamic
      if (label.length >= characterLimit) {
        return label.slice(0, label.length).substring(0, characterLimit - 1).trim() + '...'
      }
      return label
    }
  },

  /**
   * @public
   * Formats a given tooltip label to a max of 80 characters per line
   * @param {string} label - Unformatted Tooltip Label
   * @returns {string} Formatted tooltip label with max 80 characters per line
   */
  formatToolTipLabel: function (label = '') {
    if (!label) {
      return ''
    } else {
      label = label.toString() // in case label passed in is not a string
      const lineCharLimit = 80 // TODO: to be made dynamic in accordance to canvas width
      const stringArray = label.split(' ')
      let line = ''
      let finalLabel = ''
      // eslint-disable-next-line no-unused-vars
      for (const str of stringArray) {
        if (line.length + str.length <= lineCharLimit) {
          line += str + ' ' // append str to line
        } else {
          finalLabel += line + '\n'
          line = str + ' ' // reset line - str goes to next line
        }
      }
      return finalLabel + line.trim() // add leftover line (trimmed) if not empty
    }
  },

  tooltipTitleCallback: function (toolTipItemArray, data) {
    const label = data.labels[toolTipItemArray[0].index]
    return this.formatToolTipLabel(label)
  },

  tooltipLabelCallback: function (toolTipItem, data) {
    const value = data.datasets[0].data[toolTipItem.index]
    return 'Count: ' + value
  },

  chartHasBrokenQueries: function (chart) {
    if (!chart) { // undefined or false
      return false
    }
    // Return true as long as a single dataset has empty query_id
    return chart.c_datasets.some(ds => ds.query_id === '')
  },

  shouldDisplayLegend: function (rawChartData = {}) {
    if (!rawChartData.generated_details || !rawChartData.generated_details.labels ||
        rawChartData.chart_type !== 'PIE') {
      return true
    } else {
      return rawChartData.generated_details.labels.length < 21
    }
  },

  getColourSet: function (proppedColourSet = '', rawChartDataColourSet = '') {
    if (!proppedColourSet && !rawChartDataColourSet) {
      const defaultColourSetKey = Vue.prototype.$DASHBOARD('DEFAULT_COLOURSET_KEY')
      return Vue.prototype.$DASHBOARD('COLOURSET')[defaultColourSetKey].VALUE // default fail-safe
    } else {
      // proppedColourSet will override rawChartData.colour_set if it is not empty
      return !proppedColourSet ? rawChartDataColourSet : proppedColourSet
    }
  },

  /**
   * @public
   * Checks if any one querySecurityGroup is contained in userSecurityGroups. If yes, return True
   * @param {Array} userSecurityGroups - Array of objects
   * @param {Array} querySecurityGroups - Array of objects
   * @returns {Boolean} Returns true if user has access to view the given query based on their access / security groups
   */
  hasAccessGroupToGivenQuery: function (userSecurityGroups, querySecurityGroups) {
    if (querySecurityGroups.length === 0) { // query is broken (bc sg returned is []) i.e. deleted (or changed - not yet implemented)
      return true // return true so that it will not trigger the access errors, but the broken query errors instead
    }

    for (const qsg of querySecurityGroups) {
      if (qsg.gid === '0' || userSecurityGroups.some(usg => isEqual(qsg, usg))) { // lodash isEqual does a value check rather than reference
        return true
      }
    }
    return false
  }
}

export { DashboardUtils }
export default DashboardUtils
