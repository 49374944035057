<template>
  <b-alert variant="danger" :show="error !== false" class="mt-2 text-center alert-box">
    <div>
      <la-warning />
    </div>
    <span v-if="serverError">{{serverError}}</span>
    <span v-else-if="(error === missingRequiredFieldWarn)" class="font-weight-bold">
      {{error}}
    </span>
    <span v-else>{{parsedErrorMessage}}</span>
  </b-alert>
</template>

<script>
import LaWarning from '@/assets/la-warning.svg'
// This ErrorHandler is used for ChartBuilder only
export default {
  components: {
    LaWarning
  },
  props: {
    error: {
      default: false
    },
    errorMessage: {
      default: false
    },
    missingRequiredFieldWarn: {
      default: false
    }
  },
  computed: {
    serverError () {
      const serverErrorMsg = this.$INSIGHT('ERROR').CODE_500.IDENTIFIER
      const errorMessage = this.error?.message
      if (errorMessage?.includes(serverErrorMsg)) {
        return this.$INSIGHT('ERROR').CODE_500.CUSTOM_MESSAGE
      } else {
        return false
      }
    },
    parsedErrorMessage () {
      // If errorMessage prop return an object => { code, message }, then just get the message only
      if (this.errorMessage?.message) {
        return this.errorMessage.message
      }
      return this.errorMessage?.toString()
    }
  },
  name: 'error-handler'
}
</script>

<style lang="scss" scoped>
.alert-box {
  width: 96.5%;
  margin-left: auto;
  margin-right: auto;
}

svg {
  width: 25px;
  height: 25px;
}
</style>
