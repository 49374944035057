<template>
  <b-modal :aria-label="modalTitle" v-model="showModal" :title="modalTitle" @ok="handleOk" @hide="handleHide" okVariant="success" okTitle="Yes" cancelTitle="No">
    <p class="my-4">{{ modalText }}</p>
  </b-modal>
</template>

<script>
import ToastMessage from '@/utils/toast_message'

export default {
  name: 'DeleteModal',
  props: {
    item: {
      type: String
    },
    itemId: {
      type: String,
      required: true
    },
    modalShow: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    showModal: false
  }),
  computed: {
    modalText () {
      return this.$INSIGHT('DELETE_MODAL').TEXT
    },
    modalTitle () {
      return `${this.$INSIGHT('DELETE_MODAL').MISC.WORD.DELETE} ${this.item} ${this.itemId}`
    }
  },
  methods: {
    handleOk (bvModalEvt) {
      // Trigger submit handler
      this.$emit('ok', true)
    },
    handleHide (bvModalEvt) {
      // Trigger submit handler
      this.$emit('hide', true)
    },
    recordDeleted (success) {
      const h = this.$createElement
      let toastContent, toastText
      if (success) {
        ToastMessage.showDeletedSuccess({
          vueInstance: this,
          prefixName: this.item,
          name: this.itemId
        })
      } else {
        toastContent = { title: this.$INSIGHT('DELETE_MODAL').TOAST_TEXT.FAILED.TITLE, variant: this.$INSIGHT('DELETE_MODAL').TOAST_TEXT.FAILED.VARIANT }
        toastText = h(
          'p',
          { class: ['text-left', 'mb-0'] },
          [
            `${this.$INSIGHT('DELETE_MODAL').TOAST_TEXT.FAILED.TEXT} `,
            this.item,
            ' ',
            h('strong', {}, this.itemId)
          ]
        )
        this.$root.$bvToast.toast(toastText, {
          title: toastContent.title,
          variant: toastContent.variant,
          solid: true
        })
      }
    }
  },
  watch: {
    modalShow (value) {
      this.showModal = value
    }
  }
}
</script>
