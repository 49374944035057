<template>
  <b-card id="publish-status" class="mt-2 publish-status" no-body>
    <b-card-body>
      <div class="publish-status-header">
        <span class="title"><strong>{{$DASHBOARD('COMPONENTS').PUBLISHSTATUS.TITLE}}</strong></span>
        <la-info-circle class="info-circle" v-b-tooltip:publish-status.hover.leftbottom :title="publishStatusTooltip"/>
      </div>

      <div id="private-option-wrapper" class="private-option-wrapper" v-if="isCreator">
        <b-form-radio
          v-model="isPrivate"
          :value="true"
          :disabled="!isCreator"
          v-b-tooltip:private-option-wrapper.hover.topright
          :title="privateOptionTooltip"
        >
          {{$DASHBOARD('COMPONENTS').PUBLISHSTATUS.PRIVATE}}
        </b-form-radio>
      </div>
      <b-form-radio v-model="isPrivate" :value="false">
        {{$DASHBOARD('COMPONENTS').PUBLISHSTATUS.PUBLISH_TO_GROUPS}}
      </b-form-radio>

      <div class="checkbox-option-container">
        <div class="checkbox-space">
          <span v-if="userPermittedGroups.length === 0" class="info-text"><em>No groups to publish to</em></span>
          <div v-else>
            <b-form-checkbox
              class="pr-2"
              v-model="selectAllGroups"
              @change="selectAllGroupsIndeterminate = false"
              :disabled="isPrivate"
            >
              {{$DASHBOARD('COMPONENTS').PUBLISHSTATUS.SELECT_ALL}}
            </b-form-checkbox>
            <b-form-checkbox-group>
              <b-form-checkbox
                class="pr-2"
                v-for="group in userPermittedGroups"
                v-model="localSelectedGroups"
                :key="group.group_id"
                :value="group.group_id"
                :disabled="isPrivate"
              >
                {{ group.group_name }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import LaInfoCircle from '@/assets/la-info-circle.svg'

export default {
  name: 'publish-status',
  components: {
    LaInfoCircle
  },
  data: () => ({
    isPrivate: true, // retrieved with $refs in ChartBuilder
    selectAllGroups: false, // retrieved with $refs in ChartBuilder
    selectAllGroupsIndeterminate: false, // Internal tracking only
    localSelectedGroups: [] // retrieved with $refs in ChartBuilder
  }),
  props: {
    isCreator: {
      type: Boolean,
      default: true
    },
    allSelectedGroups: {
      type: Array,
      default: () => ([])
    },
    userPermittedGroups: {
      type: Array,
      default: () => ([])
    }
  },
  mounted () {
    this.populateData()
  },
  computed: {
    ...mapState('dashboard', {
      selectedQueries: 'selectedQueries'
    }),
    userPermittedGroupsIds () {
      return this.userPermittedGroups.map(x => x.group_id)
    },
    selectableUserPermittedGroupsIds () {
      return this.userPermittedGroups
        .filter(upg => this.groupHasAllQuerySecurityGroups(upg.access_groups))
        .map(upg => upg.group_id)
    },
    offendingSelectedGroupNames () { // used by Chartbuilder
      return this.localSelectedGroups
        .filter(id => !this.isIdInSelectableGroups(id))
        .map(offendingId => {
          for (const group of this.userPermittedGroups) {
            if (group.group_id === offendingId) {
              return group.group_name
            }
          }
        })
    },
    publishGroupSelectedWithNoSelectedGroups () { // used by Chartbuilder
      return !this.isPrivate && this.localSelectedGroups.length === 0
    },
    publishStatusTooltip () {
      return this.$DASHBOARD('COMPONENTS').PUBLISHSTATUS.TOOLTIP
    },
    privateOptionTooltip () {
      return this.$DASHBOARD('COMPONENTS').PUBLISHSTATUS.PRIVATE_TOOLTIP
    }
  },
  methods: {
    populateData () {
      if (!this.isCreator || this.allSelectedGroups.length > 0) {
        this.isPrivate = false
        this.localSelectedGroups = this.userPermittedGroupsIds.filter(x => this.allSelectedGroups.includes(x)) // intersection
      }
    },
    selectAllLocalGroups () {
      this.localSelectedGroups = this.userPermittedGroupsIds
    },
    clearAllLocalGroups () {
      this.localSelectedGroups = []
    },
    /**
     * Checks if a given security groups are all matching at least one of selectedQueries' security groups, for all queries
     * @param groupSecurityGroups {object} key - id of security group {string}; value - security group details {object}
     * @returns {boolean}
     */
    groupHasAllQuerySecurityGroups (groupSecurityGroups = {}) {
      // Get array of gids from keys
      const groupSecurityGIDs = Object.keys(groupSecurityGroups)
      // add public security group gid, '0'
      groupSecurityGIDs.push('0')

      // Each query can have multiple security groups.
      // Every gid in groupSecurityGroups just need to have at least one matching security group across ALL queries.
      for (const query of this.selectedQueries) {
        const querySecurityGIDs = query.securityGroups.map(x => x.gid) // get array of gids of query
        if (!querySecurityGIDs.some(gid => groupSecurityGIDs.indexOf(gid) >= 0)) { // query gid DOES NOT exist in group gids
          return false
        }
      }
      return true
    },
    isIdInSelectableGroups (groupId) { // abstracted into method for better unit testing
      return this.selectableUserPermittedGroupsIds.includes(groupId)
    }
  },
  watch: {
    isPrivate: function (newIsPrivate) {
      if (newIsPrivate) {
        // when isPrivate is true, we need to clear all groups
        this.clearAllLocalGroups() // will trigger watcher to clear
      }
    },
    selectAllGroups: function (newSelectAllGroups) {
      // Only make changes if the selectAllGroupsIndeterminate is false
      if (!this.selectAllGroupsIndeterminate) {
        if (newSelectAllGroups) {
          this.selectAllLocalGroups()
        } else {
          this.clearAllLocalGroups()
        }
      }
    },
    localSelectedGroups: function (newArray) {
      if (newArray.length === this.userPermittedGroups.length) {
        this.selectAllGroups = true
        this.selectAllGroupsIndeterminate = false
      } else if (newArray.length === 0) {
        this.selectAllGroups = false
        this.selectAllGroupsIndeterminate = false
      } else {
        this.selectAllGroups = false
        this.selectAllGroupsIndeterminate = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.publish-status { // overrides bootstrap padding of 1.25rem during render
  .card-body {
    padding: 1rem;
  }
  .card-footer {
    padding: 1rem;
  }
}

.private-option-wrapper {
  display: inline-block;
}

.status-label {
  display: flex;
  align-items: center;
}

.checkbox-option-container {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-left: 27px;
  margin-bottom: 5px;
  max-height: 110px;
  overflow: auto;

  .checkbox-space {
    padding: 8px 0 5px 10px;
  }
}

.publish-status-header {
  display: flex;
  justify-content: space-between;

  .title {
    align-self: flex-start;
  }

  .info-circle {
    align-self: flex-end;
  }

  svg {
    width: 1.5em;
    height: 1.5em;
    cursor: default;
    pointer-events: bounding-box; // so that hovering works on entire svg including empty spaces

    :hover {
      fill: #F08521;
      color: #F08521;
      text-decoration: none;
    }
  }
}

</style>
