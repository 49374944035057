<template>
  <b-modal
    :aria-label="modalTitle"
    v-model="showModal"
    :title="modalTitle"
    @ok="handleOk"
    @hide="handleHide"
    okVariant="success"
    okTitle="Yes"
    cancelTitle="No"
  >
    <div class="alert alert-warning alert-container">
      <strong style="white-space: pre-line;">{{ warningMessage }}</strong>
      <ul v-if="offendingGroups.length > 0">
        <li v-for="groupName of offendingGroups" :key="groupName">
          {{ groupName }}
        </li>
      </ul>
    </div>
    <p class="my-4 font-weight-bold" style="padding: 16px 1.25rem 0px;"> {{ this.$DASHBOARD('COMPONENTS').SUBMITMODAL.ARE_YOU_SURE }} </p>
  </b-modal>
</template>

<script>
export default {
  name: 'submit-modal',
  props: {
    propShowModal: {
      type: Boolean,
      required: true
    },
    offendingGroups: {
      type: Array,
      required: true
    },
    warningMessage: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showModal: false
  }),
  watch: {
    propShowModal (value) {
      this.showModal = value
    }
  },
  computed: {
    modalTitle () {
      // To be dynamically determined should any new titles be used in the future
      return this.$DASHBOARD('COMPONENTS').SUBMITMODAL.DEFAULT_TITLE
    }
  },
  methods: {
    reset () {
      this.showModal = false
    },
    handleOk () {
      this.$emit('ok')
      this.reset()
    },
    handleHide () {
      this.$emit('hide')
      this.reset()
    }
  }
}
</script>

<style scoped>
.alert-container {
  margin-bottom: 0px;
}
</style>
