<template>
  <div class="results">
    <div
      :id="chart.id"
      class="record col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12"
      v-for="chart of charts"
      :key="chart.id"
    >
      <!-- single chart -->
      <div class="inner shadow-sm fade-in fast">
        <div class="header">
          <div class="chart-title">
            <slot name="title" :chart="chart"/>
          </div>

          <span class="actions" v-if="hasActions">
            <b-button-group size="sm">
              <slot name="viewable" :chart="chart" />
              <slot name="edit" :chart="chart" />
              <slot name="delete" :chart="chart" />
            </b-button-group>
          </span>
        </div>

        <div class="chart-details">
          <slot name="fields-and-queries" :chart="chart" />
        </div>

        <hr />

        <div class="graphic">
          <slot name="graphic" :chart="chart" />
        </div>

        <div class="bottom-row d-flex flex-row-reverse align-items-center justify-content-between">
          <slot name="star" :chart="chart" />
          <slot name="info" :chart="chart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chart-cards',
  props: {
    charts: {
      type: Array,
      default: () => ([])
    },
    hasActions: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.results {
    display: flex;
    flex-flow: row wrap;

    .record {
      padding: 14px;
      text-align: left;
      display: flex;
      flex-basis: 100%;
      position: relative;

      .inner {
        background: #FFF;
        border: 1px solid rgba(0, 0, 0, 0.125);
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        align-self: stretch;
        padding: 20px;
      }

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 34px;
        width: 100%;

        .chart-title {
          font-size: 1em;
          font-weight: bold;
          flex-grow: 1;
          vertical-align: middle;

          a {
            color: black;
          }
        }

        .actions {
          align-self: flex-end;
        }
      }

      .chart-details {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        font-size: 0.9em;
        justify-content: left;

        .fields-and-queries {
          flex-grow: 0;
          flex-shrink: 0;
          padding: 5px;
        }
      }

      .graphic {}

      .bottom-row {
        margin-top: auto;
      }
    }
  }
</style>
