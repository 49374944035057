<template>
  <b-modal
    id="modal-window"
    :visible="isQueryModalOpen"
    size="lg"
    hide-footer
    centered
    @hide="close"
    @close="close"
  >
    <template v-slot:modal-title>
      <h5 class="modal-title mr-1">Select a Query</h5>
      <la-info-circle class="info-circle" v-b-tooltip:modal-window.hover.righttop :title="$DASHBOARD('COMPONENTS').QUERYMODAL.NO_PARAMETERISED_QUERIES"/>
    </template>

    <div class="queries">
      <search-sort-controls
        class="search-and-sort-controls"
        :loading="loading"
        :showSortAndRefresh="true"
        :hasClearSearchCross="true"
        :currentSentSearch="searchTerm"
        :hasAdvancedSearch="false"
        :hasModelSelection="false"
        :sortFields="items.fields ? items.fields : {}"
        :isForEntityOrLink="false"
        :initialSortText="curSortText"
        sortCustomIdField="record_id"
        :searchCount="items.count ? items.count : 0"
        :searchNoun="$INSIGHT('INDEX_QUERIES').TITLE"
        @sort="sort"
        @search="doSearch"
        @refresh="fetchQueryList(false)"
        @lastSelectedSortText="setLatestSortText"
      />

      <div class="results-area-container no-overflow-anchor">
        <div class="loader text-center" v-if="hasInitialised === false">
          <b-spinner variant="secondary" large label="Loading..."/>
        </div>

        <error-handler
          v-if="error !== false && loading === false"
          :error="displayError"
          :showError="showError"
          variant="danger"
          @dismiss-error="showError = false"
        />

        <div class="results-area" v-if="hasInitialised === true">
          <index-cards :items="items.results" :searchQuery="searchTerm" :sortField="sortBy">
            <template v-slot:name="{ item }">
              <router-link
                :to="{ name: 'generate-query', params: { id: item.record_id } }"
              >{{ item.name }}</router-link>
            </template>
            <template v-slot:fields="{ item }">
              <div class="field col-sm-3 col-md-2 col-lg-3">
                <div>
                  <strong>Model</strong>
                </div>
                <div>{{ item.model }}</div>
              </div>
              <div class="field col-sm-3 col-md-2 col-lg-3">
                <div>
                  <strong>Description</strong>
                </div>
                <div>{{ item.description !== null && item.description !== '' ? item.description : 'No description' }}</div>
              </div>
            </template>

            <template v-slot:view="{ item }">
              <b-btn @click="sendQuery(item)" :disabled="checkSelected(item.record_id)" :variant="variantSelectColour(item.record_id)">Select</b-btn>
            </template>
          </index-cards>
        </div>
        <div class="load-more d-flex justify-content-center">
          <b-button
            v-if="items.next"
            class="load-more-button"
            variant="outline"
            @click="fetchQueryList(true)"
          >
            <b-badge pill variant="secondary" v-if="loading === false">{{$LOCAL('LoadMore')}}</b-badge>
            <b-spinner small v-if="loading === true" label="Loading"/>
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import LaInfoCircle from '@/assets/la-info-circle.svg'
import IndexCards from '@/modules/insight/components/IndexCards'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'
import { filter, find } from 'lodash'

export default {
  name: 'query-modal',
  components: {
    ErrorHandler,
    LaInfoCircle,
    IndexCards,
    SearchSortControls
  },
  data: () => ({
    loading: false,
    page: 1,
    searchTerm: '',
    showError: false,
    displayError: {
      code: -1,
      message: ''
    },
    sortBy: ['-create_date', 'record_id'],
    curSortText: Vue.prototype.$INSIGHT('SORT').DEFAULT_SORTTEXT
  }),
  props: {
    scenario: {
      type: Number,
      default: 0
    }
  },
  async mounted () {
    await this.fetchQueryList()
  },
  computed: {
    ...mapState('insight/queries', {
      items: 'queries',
      error: 'error'
    }),
    ...mapState('dashboard', {
      selectedQueries: 'selectedQueries',
      isQueryModalOpen: 'isQueryModalOpen',
      storedOutputModelsAndFields: 'storedOutputModelsAndFields',
      oneOutputModelTypeSelected: 'oneOutputModelTypeSelected'
    }),
    ...mapGetters('dashboard', {
      seriesSelection: 'seriesSelection'
    }),
    hasInitialised () {
      return typeof this.items.results === 'object'
    }
  },
  watch: {
    error: {
      handler: function (newError) {
        if (newError !== false) {
          this.showError = true
          this.displayError = {
            code: newError.code,
            message: newError.message.detail ? newError.message.detail : newError.message
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('insight/queries', [
      'getQueries',
      'getAccessGroupsOfSingleQuery'
    ]),
    ...mapActions('dashboard', [
      'appendSelectedQuery',
      'getAllOutputModelTypesAndFieldsByQueryId',
      'updateQueryModalOpen',
      'updateIsAddQueryLoading',
      'toggleOutputModelStatusInSelectedQueries'
    ]),
    /**
     * @public
     * @async
     * fetchQueryList () is a function that fetch a list of queries. It is used in mounted ().
     * @param {boolean} isAppend - default false value.
     */
    async fetchQueryList (isAppend = false) {
      this.loading = true
      isAppend ? this.page++ : (this.page = 1)
      const params = {
        isChartable: 'True', // retrieve chartable queries only
        page: this.page,
        search: this.searchTerm,
        ordering: this.sortBy.join(','),
        perPage: 10
      }
      await this.getQueries({ params, isAppend })
      this.loading = false
    },
    /**
     * @public
     * doSearch () is a function that searches for queries based on searchTerm. It is used in the search box in Query Modal.
     * @param {string} searchTerm - search term. e.g. 'Fiona'
     */
    doSearch (searchTerm) {
      this.searchTerm = searchTerm
      this.fetchQueryList(false)
    },
    /**
     * @public
     * clearInput () is a function that clears searchTerm and it triggers fetchQueryList () once again.
     */
    clearInput () {
      this.searchTerm = ''
      this.fetchQueryList(false)
    },
    /**
     * @public
     * Sets status of isQueryModalOpen (in vuex) to false
     */
    close () {
      this.updateQueryModalOpen(false)
    },
    /**
     * @public
     * @async
     * sendQuery () is a function that formats and adds selected query into selectedQueries of the Vuex store.
     * @param {Object} item - An object for a single query.
     *                 item.record_id - The query id of the single query.
     */
    async sendQuery (item) {
      this.updateIsAddQueryLoading(true) // toggle loading state to false
      this.updateQueryModalOpen(false) // close queryModal

      if (this.selectedQueries.find(query => parseInt(query.queryId) === parseInt(item.record_id))) {
        this.updateIsAddQueryLoading(false)
        return // do nothing if query is already in selectedQueries list
      } else {
        const recordId = item.record_id
        // Get access groups formatted to dashboard format - List of objects storing gid and name - and update the item
        // The query format of a string e.g. "x | x | x" will not work with the dashboard's implementation for other components.
        const queryAccessGroups = await this.getAccessGroupsOfSingleQuery({ queryId: recordId })
        const updatedItem = { ...item, access_group: queryAccessGroups }
        // Fetch Output models and fields and store in vuex
        await this.getAllOutputModelTypesAndFieldsByQueryId({ queryId: recordId })
        // append to selectedQueries list
        this.appendSelectedQuery({ selectedQuery: updatedItem, allOutputModelTypesandFields: this.storedOutputModelsAndFields })

        // if scenario is 3 and there exist selected models already,
        // set all new series' selected as true as long as it is the same type as current type
        if (this.scenario === 3 && this.oneOutputModelTypeSelected.length === 1) {
          const selectedOutputModels = filter(this.seriesSelection, { type: this.oneOutputModelTypeSelected[0] })
          selectedOutputModels.forEach((filteredOutputModel) => {
            this.toggleOutputModelStatusInSelectedQueries({ outputModel: filteredOutputModel, value: true })
          })
        }
      }
      this.updateIsAddQueryLoading(false)
    },
    /**
     * @public
     * variantSelectColour () is a function that returns the colour for the select button in Query Modal.
     * It formats the queryId received into a number and identifies whether it is present in selectedQueries.
     * @param {string or number} queryId - Query / Record Id
     * @returns {string} Returns 'secondary' if query has already been selected. 'success' otherwise.
     */
    variantSelectColour (queryId) {
      return this.checkSelected(parseInt(queryId)) ? 'secondary' : 'success'
    },
    /**
     * @public
     * checkSelected () is a function that checks if a query has already been selected.
     * It formats the queryId received in string into a integer and identifies whether it is present in selectedQueries.
     * @param {string or number} queryId - Query / Record Id
     * @returns {boolean} Returns true if query has already been selected. False otherwise.
     */
    checkSelected (queryId) {
      return !!find(this.selectedQueries, { queryId: parseInt(queryId) })
    },
    sort (sortBy) {
      this.sortBy = sortBy
      this.fetchQueryList(false)
    },
    setLatestSortText (latestSortText) {
      this.curSortText = latestSortText
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-title {
  display: inline-block;
  margin-bottom: 0px;
}

.info-circle {
  display: inline-block;
  margin-top: -4px;

  width: 1.2em;
  height: 1.2em;
  cursor: default;
  pointer-events: bounding-box; // so that hovering works on entire svg including empty spaces

  :hover {
    fill: #F08521;
    color: #F08521;
    text-decoration: none;
  }
}

.queries {
  position: relative;

  .search-and-sort-controls {
    padding: 0 14px;
  }

  .results-area-container {
    overflow: auto;
    height: 400px;
    margin-top: -5px;
  }

  .load-more {
    .load-more-button {
      margin: 5px 0px 15px 0px;
      text-align: center;
    }
  }
}

// Override bootstrap's classes
::v-deep .modal-content {
  font-family: Roboto, sans-serif;
}
::v-deep .modal-body {
  background: #f7f7f7;
}

</style>
