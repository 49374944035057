import { mapMutations } from 'vuex'

export default {
  data: () => ({
    noMoreChangesToIndexStateShouldBeMade: false
    // Optionally useful due to the fact that beforeRouteLeave happens before beforeDestroy / destroy.
    // If resetIndexState is successfully called in checkNavigationWithinDashboardAndReset(), and
    // indexState is still edited in a parent component's beforeDestroy / destroy, indexState will basically not be 'resetted'.
    // Hence, any component implementing this mixin can utilise noMoreChangesToIndexStateShouldBeMade to check
    // if the change made in its beforeDestroy / destroy should be called or not.
    // Example of component using noMoreChangesToIndexStateShouldBeMade: Dashboard > Index.vue
    // Example of component NOT using noMoreChangesToIndexStateShouldBeMade: Dashboard > ChartBuilder.vue
  }),
  methods: {
    ...mapMutations('dashboard', [
      'resetIndexState'
    ]),
    // dont clear indexState if navigating within dashboard module
    checkNavigationWithinDashboardAndReset (to, from, next) {
      if (!to.fullPath.startsWith('/dashboard/')) {
        this.resetIndexState()
        this.noMoreChangesToIndexStateShouldBeMade = true
      } else {
        this.noMoreChangesToIndexStateShouldBeMade = false
      }
      next()
    }
  }
}
