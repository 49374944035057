<script>
import { Bar } from 'vue-chartjs'
import 'chartjs-plugin-labels'
import DashboardUtils from '@/modules/dashboard/utils'
import CurrencyStringMixin from '@/modules/insight/mixins/CurrencyStringMixin'

export default {
  name: 'multi-bar-chart',
  extends: Bar,
  mixins: [CurrencyStringMixin],
  data: () => ({
    aggregateType: 'COUNT', // default aggregate type for now
    formattedChartData: [],
    formattedChartOptions: []
  }),
  props: {
    rawChartData: {
      type: Object,
      // skeleton of a rawChartData object
      default: () => ({
        id: -1, // optional
        title: '', // optional
        chart_type: '', // optional
        label_type: '', // optional
        colour_set: '',
        c_datasets: [], // optional
        generated_details: {
          field_label: '',
          query_details: [], // optional
          labels: [],
          values: []
        }
      })
    },
    colourSet: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.formattedChartData = this.getFormattedChartData()
    this.formattedChartOptions = this.getFormattedChartOptions()
    this.renderMultiBarChart()
  },
  computed: {
    computedColourSet () {
      return this.getColourSet(this.colourSet, this.rawChartData.colour_set)
    }
  },
  methods: {
    getColourSet: DashboardUtils.getColourSet,
    renderMultiBarChart () {
      this.renderChart(this.formattedChartData, this.formattedChartOptions)
    },
    /**
     * Returns the formatted labels of the chart
     * @param {Array} labels - List of unformatted labels
     * @param {String} chartDataFieldFormat - Format of field e.g. Currency
     * @returns {Array} List of formatted labels
     */
    getFormattedGeneratedDetailsLabels (labels, chartDataFieldFormat) {
      if (chartDataFieldFormat === this.$INSIGHT('VALIDATION_FORMAT').CURRENCY) {
        // Format labels to currency format
        const formattedLabels = []
        labels.forEach(label => formattedLabels.push(this.currencyString(label)))
        return formattedLabels
      } else {
        return labels
      }
    },
    getFormattedChartData () {
      return {
        labels: this.getFormattedGeneratedDetailsLabels(this.rawChartData.generated_details.labels, this.rawChartData.generated_details.field_format),
        datasets: this.rawChartData.generated_details.values // multiple datasets
      }
    },
    getFormattedChartOptions () {
      // TODO: Allow customisation in options
      return {
        plugins: {
          colorschemes: {
            scheme: this.computedColourSet
          },
          labels: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 1
            }
          }],
          xAxes: [{
            ticks: {
              callback: (tick) => DashboardUtils.shortenXAxesLabel(tick)
            }
          }]
        },
        tooltips: {
          callbacks: {
            title: /* istanbul ignore next */ (toolTipItemArray, data) => DashboardUtils.tooltipTitleCallback(toolTipItemArray, data) // unable to test this
          }
        }
      }
    }
  },
  watch: {
    computedColourSet: {
      handler () {
        if (this.$data._chart) {
          this.formattedChartOptions = this.getFormattedChartOptions() // update colour scheme
          this.$data._chart.destroy()
          this.renderMultiBarChart()
        }
      }
    }
  }
}
</script>
