<template>
  <div class="data-series" id="data-series">
    <b-card :border-variant="labelTypeError()">
      <div class="text-left" v-if="!selectedQueries.length || (form.chartType === null || form.chartTitle === '')">
        {{$DASHBOARD('COMPONENTS').DATASERIES.REQUIRES_AT_LEAST_ONE_QUERY}}
      </div>
      <b-alert v-else-if="!canViewQueries || !hasAccessGroupToAllQueries" show class="card-body-alert" variant="danger">
        {{$DASHBOARD('NO_PERMS_ERROR_MESSAGE').QUERIES.VIEW_NO_ACCESS_GROUPS}}
      </b-alert>
      <div v-else>
        <b-row no-gutters>
          <b-col md="6" class="text-left">
            <b-card-body>
              <div class="series">
                <span class="series-title mandatory">{{$DASHBOARD('COMPONENTS').DATASERIES.MANDATORY_FIELDS.SERIES}}</span>
                <la-info-circle class="info-circle" v-b-tooltip:data-series.hover :title="seriesInfoToolTip"/>
              </div>
              <b-list-group>
                <b-list-group-item button class="text-center"
                                   v-for="(outputModel, index) in seriesSelection" v-bind:key="index"
                                   :active="outputModel.selected" :style="getListStyle(outputModel.queryId, outputModel.selected)"
                                   @click="modelSelected(outputModel)">
                  <b-badge class="badge-tick" v-if="outputModel.selected">&check;</b-badge>
                  <span>{{ outputModel.name }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-col>
          <b-col md="6">
            <b-card-body class="text-left">
              <div v-if="scenario !== 0" class="label">
                <span v-if="scenario === 1 || scenario === 3" class="label-title mandatory">{{$DASHBOARD('COMPONENTS').DATASERIES.MANDATORY_FIELDS.LABEL}}</span>
                <span v-if="scenario === 2" class="label-title mandatory">{{$DASHBOARD('COMPONENTS').DATASERIES.MANDATORY_FIELDS.LABELS}}</span>
                <la-info-circle class="info-circle" v-b-tooltip:data-series.hover :title="labelInfoToolTIp"/>
              </div>
              <b-form-group v-if="scenario === 1 || scenario === 3" class="mandatory" :invalid-feedback="labelErrorMessage" :state="labelValid">
                <b-form-select
                  class="label-box"
                  v-model="form.selectedLabel"
                  type="text"
                  :state="labelValid"
                  :options="labelOptions()"
                  @change="updatePreviewButtonFalse"
                  required
                />
              </b-form-group>
              <div v-else-if="scenario === 2" :key="index" v-for="(item, index) in seriesSelection">
                <b-list-group-item
                  class="series-label-box d-flex justify-content-between align-items-center"
                  v-if="item.selected"
                  type="text"
                >
                  {{ item.name }}
                </b-list-group-item>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { filter, concat } from 'lodash'
import { BBadge } from 'bootstrap-vue'
import LaInfoCircle from '@/assets/la-info-circle.svg'

export default {
  name: 'data-series',
  components: {
    LaInfoCircle,
    BBadge
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    scenario: {
      type: Number,
      default: 0
    },
    labelValid: {
      type: Boolean,
      default: false
    },
    labelErrorMessage: {
      type: String,
      default: ''
    },
    labelTypeStatus: {
      type: Boolean,
      default: false
    },
    canViewQueries: {
      type: Boolean,
      default: false
    },
    hasAccessGroupToAllQueries: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    returnModels: [],
    returnFields: [
      { value: null, text: 'Please select at least one series' } // not using constant here
    ],
    seriesInfoToolTip: '',
    labelInfoToolTIp: ''
  }),
  computed: {
    ...mapState('dashboard', {
      selectedQueries: 'selectedQueries',
      storedOutputModelsAndFields: 'storedOutputModelsAndFields',
      oneOutputModelTypeSelected: 'oneOutputModelTypeSelected'
    }),
    ...mapGetters('dashboard', {
      seriesSelection: 'seriesSelection'
    })
  },
  methods: {
    ...mapActions('dashboard', [
      'updatePreviewButtonFalse',
      'addOneOutputModelTypeSelected',
      'clearOneOutputModelTypeSelected',
      'toggleOutputModelStatusInSelectedQueries'
    ]),
    /**
     * @public
     * modelSelected () is a function that changes the selected status of an outputModel
     * It is used when a series the DataSeries component is selected / unselected.
     * @param {name: string, type: string, selected: boolean, queryId: number} outputModel object
     */
    modelSelected (outputModel) {
      if (this.scenario === 3) {
        let setValue
        if (this.oneOutputModelTypeSelected.length === 0) {
          this.addOneOutputModelTypeSelected({ outputModel: outputModel.type })
          setValue = true
          const selectedOutputModels = filter(this.seriesSelection, { type: outputModel.type })
          selectedOutputModels.forEach((filteredOutputModel) => {
            this.toggleOutputModelStatusInSelectedQueries({ outputModel: filteredOutputModel, value: setValue })
          })
        } else if (this.oneOutputModelTypeSelected.length === 1 && this.oneOutputModelTypeSelected[0] === outputModel.type) {
          // user clicks on existing common model type
          this.clearOneOutputModelTypeSelected()
          this.returnFields = [{ value: null, text: this.$DASHBOARD('COMPONENTS').DATASERIES.PLEASE_SELECT_AT_LEAST_ONE_SERIES }]
          setValue = false
          const selectedOutputModels = filter(this.seriesSelection, { type: outputModel.type })
          selectedOutputModels.forEach((filteredOutputModel) => {
            this.toggleOutputModelStatusInSelectedQueries({ outputModel: filteredOutputModel, value: setValue })
          })
        }
      } else { // scenario 0 (on first initialisation), 1 and 2
        this.toggleOutputModelStatusInSelectedQueries({ outputModel: outputModel })
      }
    },
    getListStyle (queryId, isActive) {
      const indexOfQuery = this.selectedQueries.findIndex(
        query => parseInt(query.queryId) === parseInt(queryId)
      )
      const defaultColourSetKey = this.$DASHBOARD('DEFAULT_COLOURSET_KEY')
      if (isActive) {
        return {
          borderColor: this.$DASHBOARD('COLOURSET')[defaultColourSetKey].BORDER[indexOfQuery],
          backgroundColor: this.$DASHBOARD('COLOURSET')[defaultColourSetKey].FILL[indexOfQuery],
          color: this.$DASHBOARD('COLOURSET')[defaultColourSetKey].TEXT[indexOfQuery]
        }
      } else {
        return { borderColor: this.$DASHBOARD('COLOURSET')[defaultColourSetKey].BORDER[indexOfQuery] }
      }
    },
    /**
     * @public
     * labelOptions () is a function that returns fields/models used in the drop down list in DataSeries component.
     * It will return either returnFields or returnModels depending on the scenario.
     */
    labelOptions () {
      if (this.scenario === 1 || this.scenario === 3) {
        return this.returnFields
      } else if (this.scenario === 2) {
        return this.returnModels
      } else {
        return ['No Scenario']
      }
    },
    /**
     * @public
     * labelTypeError () is a function that returns that border colour of InputQueries depending on whether
     * labelType error exist. It returns 'danger' when labelTypeStatus is 'true' and querySelectedStatus is 'false'
     * @returns {string} 'danger' or 'default'
     */
    labelTypeError () {
      if (this.labelTypeStatus) {
        return 'danger'
      }
      return 'default'
    }
  },
  watch: {
    seriesSelection: { // to set returnFields or returnModels whenever seriesSelection changes
      handler (series) {
        this.updatePreviewButtonFalse()
        const seriesSelected = series.filter(outputModel => outputModel.selected)
        if (this.scenario === 1) {
          const queryId = seriesSelected[0].queryId
          const outputModelSelected = this.storedOutputModelsAndFields[queryId]
            .find(outputModel => outputModel.om_name === seriesSelected[0].name)
          const fields = concat([{ value: null, text: this.$DASHBOARD('COMPONENTS').DATASERIES.PLEASE_SELECT_A_FIELD }], outputModelSelected.om_fields)
          this.returnFields = fields
        } else if (this.scenario === 2) {
          const outputModelsSelected = []
          seriesSelected.forEach(element => {
            outputModelsSelected.push(this.storedOutputModelsAndFields[parseInt(element.queryId)]
              .find(outputModel => outputModel.om_name === element.name)
              .om_name)
          })
          this.returnModels = outputModelsSelected
        } else if (this.scenario === 3 && seriesSelected.length > 0) { // if seriesSelected.length === 0 -> do nothing
          const queryId = seriesSelected[0].queryId
          const outputModelSelected = this.storedOutputModelsAndFields[queryId]
            .find(outputModel => outputModel.om_name === seriesSelected[0].name)
          const fields = concat([{ value: null, text: this.$DASHBOARD('COMPONENTS').DATASERIES.PLEASE_SELECT_A_FIELD }], outputModelSelected.om_fields)
          this.returnFields = fields
        }
      },
      immediate: true, // executes watcher once with initial value too
      deep: true
    },
    scenario: {
      immediate: true,
      handler: function (newScenario) {
        if (newScenario === 1) {
          this.seriesInfoToolTip = this.$DASHBOARD('COMPONENTS').DATASERIES.TOOLTIPS.SERIES.SELECT_ONE_OR_MORE_SERIES
          this.labelInfoToolTIp = this.$DASHBOARD('COMPONENTS').DATASERIES.TOOLTIPS.LABEL.SELECT_ONE_FIELD_AS_LABEL_EXCL_FILE_IMAGE
        } else if (newScenario === 2) {
          this.seriesInfoToolTip = this.$DASHBOARD('COMPONENTS').DATASERIES.TOOLTIPS.SERIES.SELECT_ONE_OR_MORE_SERIES
          this.labelInfoToolTIp = this.$DASHBOARD('COMPONENTS').DATASERIES.TOOLTIPS.LABEL.LABLES_ARE_DEFAULTED_TO_SELECTED_SERIES
        } else if (newScenario === 3) {
          this.seriesInfoToolTip = this.$DASHBOARD('COMPONENTS').DATASERIES.TOOLTIPS.SERIES.SELECT_SERIES_OF_SAME_MODEL_TYPE
          this.labelInfoToolTIp = this.$DASHBOARD('COMPONENTS').DATASERIES.TOOLTIPS.LABEL.SELECT_ONE_FIELD_AS_LABEL_EXCL_FILE_IMAGE
        } else {
          this.seriesInfoToolTip = this.$DASHBOARD('COMPONENTS').DATASERIES.TOOLTIPS.SERIES.SELECT_ONE_OR_MORE_SERIES
          this.labelInfoToolTIp = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mandatory {
  font-weight: bold;
}

.series, .label, .colour-set {
  display: flex;
  justify-content: space-between;
}

.series-title, .label-title {
  align-self: flex-start;
}

.series-label-box {
  width: 100%;
  height: 50px;
  background: #e9ecef;
}

.label-box {
  height: 50px;
}

.color-picker {
}

.info-circle {
  align-self: flex-end;
}

.card-body-alert {
  margin-bottom: 0;
}

svg {
  width: 1.5em;
  height: 1.5em;
  cursor: default;
  pointer-events: bounding-box; // so that hovering works on entire svg including empty spaces

  :hover {
    fill: #F08521;
    color: #F08521;
    text-decoration: none;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.badge-tick {
  background: transparent;
  color: black;
}
</style>
