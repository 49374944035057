import { mapActions, mapState } from 'vuex'
import { get } from 'lodash'

export default {
  computed: {
    ...mapState('auth', {
      userData: 'userData'
    }),
    ...mapState('insight', {
      userFavouriteList: 'userFavouriteList'
    })
  },
  methods: {
    ...mapActions('insight', [
      'getFavouriteList',
      'makeAsFavourite',
      'removeFromFavourite'
    ]),
    /**
     * @public
     * @async
     * Retrieve the mandatory and user favourite queries and set the favourite icon accordingly in document
     * @param {String} itemType (if any. If empty string, retrieves ALL favourites)
     * @param {Array} items
     */
    async checkFavourited (items = [], itemType = '') {
      if (typeof items !== 'object' || items.length === 0) return

      await this.getFavouriteList({ username: this.userData.username, itemType: itemType })
      const userFavouriteItemResult = []
      const mandatoryFavouriteItemResult = []
      for (const userFavouriteItem of this.userFavouriteList.results) {
        if (userFavouriteItem.user === null) {
          mandatoryFavouriteItemResult.push(userFavouriteItem.item_name)
        } else {
          userFavouriteItemResult.push(userFavouriteItem.item_name)
        }
      }

      // Assumption here is that item has an 'id' property.
      // In future, if anyone wants to access some other property instead, simply declare a new param e.g. 'field'
      // and do get(item, <field>, '').toString() instead.
      for (const item of items) {
        const stringId = get(item, 'id', '').toString()
        if (mandatoryFavouriteItemResult.includes(stringId)) {
          document.getElementById(`${item.id}-star`).dataset.favourite = this.$FORM('FAVOURITE').MANDATORY
        } else if (userFavouriteItemResult.includes(stringId)) {
          document.getElementById(`${item.id}-star`).dataset.favourite = this.$FORM('FAVOURITE').FAVOURITE
        } else {
          document.getElementById(`${item.id}-star`).dataset.favourite = this.$FORM('FAVOURITE').UNFAVOURITE
        }
      }
    },
    /**
     * @public
     * @async
     * Toggle the favourite function to set the record to "favourite" if it is unfavourite and vice verse
     * @param {String} itemType e.g. 'query', 'form', 'chart'
     * @param {String or Number} itemName
     * @returns {Promise<void>}
     */
    async toggleFavourite (itemType = '', itemName = '') {
      if (itemType && itemName) {
        const data = {
          item_type: itemType,
          item_name: itemName
        }

        const element = document.getElementById(`${itemName}-star`)
        if (element.dataset.favourite === this.$FORM('FAVOURITE').UNFAVOURITE) {
          await this.makeAsFavourite({ username: this.userData.username, data: data })
          element.dataset.favourite = this.$FORM('FAVOURITE').FAVOURITE
        } else {
          await this.removeFromFavourite({ username: this.userData.username, data: data })
          element.dataset.favourite = this.$FORM('FAVOURITE').UNFAVOURITE
        }
      }
    },
    async unFavouriteThis (itemType = '', itemName = '') {
      if (itemType && itemName) {
        const data = {
          item_type: itemType,
          item_name: itemName
        }
        const removeFavourite = await this.removeFromFavourite({ username: this.userData.username, data: data })
        return removeFavourite
      }
    }
  }
}
