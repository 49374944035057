<template>
  <div class="input-queries">
    <b-form-group id="input-group-4" class="query-container">
      <b-card class="text-left" :border-variant="labelTypeError()">
        <div v-if="!form || !form.chartType || form.chartTitle === ''" class="query">
          {{ $DASHBOARD('COMPONENTS').INPUTQUERIES.CHART_TYPE_AND_TITLE_NEEDS_TO_BE_FILLED }}
        </div>
        <div v-else-if="!selectedQueries.length" class="query">
          {{ $DASHBOARD('COMPONENTS').INPUTQUERIES.CLICK_PLUS_TO_SELECT_A_QUERY }}
          <b-overlay :show="isAddQueryLoading" spinner-small spinner-variant="secondary" class="button-add spinner-icon">
            <b-button size="sm" @click="updateQueryModalOpen()" variant="success">
              <la-plus />
            </b-button>
          </b-overlay>
        </div>
        <div v-else>
          <div :key="query.queryId" v-for="(query, index) in selectedQueries">
            <div class="query">
              <b-button
                class="button-delete"
                size="sm"
                @click="deleteSelectedQuery(query.queryId)"
                variant="danger"
              >
                <la-trash />
              </b-button>
              <div class="value">
                <b-card :style="{ borderColor: borderColour(query.queryId) }">
                  <b-alert class="card-body-alert" v-if="!query.queryName" show variant="danger">
                    {{$DASHBOARD('COMPONENTS').INPUTQUERIES.ERROR_MESSAGE.PREVIOUSLY_SELECTED_QUERY_BROKEN}}
                  </b-alert>
                  <b-alert class="card-body-alert" v-else-if="!canViewQueries || !hasAccessGroupToGivenQuery(userAccessGroups, query.securityGroups)"
                    show variant="danger">
                    {{$DASHBOARD('NO_PERMS_ERROR_MESSAGE').QUERY.VIEW_NO_ACCESS_GROUPS}}
                  </b-alert>
                  <b-card-text v-else>
                    {{ index+1 }}. {{ query.queryName }}
                  </b-card-text>
                </b-card>
              </div>
              <div v-if="index+1 === selectedQueries.length && selectedQueries.length < 5">
                <b-overlay :show="isAddQueryLoading" spinner-small spinner-variant="secondary" class="button-add spinner-icon">
                  <b-button size="sm" @click="updateQueryModalOpen()" variant="success">
                    <la-plus />
                  </b-button>
                </b-overlay>
              </div>
              <div v-else class="column"></div>
            </div>
          </div>
        </div>
      </b-card>
    </b-form-group>
    <query-modal class="query-modal" :scenario="scenario"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LaPlus from '@/assets/la-plus.svg'
import LaTrash from '@/assets/la-trash.svg'
import QueryModal from '../components/QueryModal'
import DashboardUtils from '@/modules/dashboard/utils'

export default {
  name: 'input-queries',
  components: {
    QueryModal,
    LaPlus,
    LaTrash
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    labelTypeStatus: {
      type: Boolean,
      default: false
    },
    scenario: {
      type: Number,
      default: 0
    },
    canViewQueries: {
      type: Boolean,
      default: false
    },
    userAccessGroups: { // retrieved from parent, chartbuilder, which retrieves it from auth vuex store
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    ...mapState('dashboard', {
      selectedQueries: 'selectedQueries',
      isAddQueryLoading: 'isAddQueryLoading'
    }),
    querySelectedStatus () {
      return JSON.parse(JSON.stringify(this.selectedQueries)).length > 0
    }
  },
  methods: {
    ...mapActions('dashboard', [
      'removeSelectedQuery',
      'updateQueryModalOpen'
    ]),
    hasAccessGroupToGivenQuery: DashboardUtils.hasAccessGroupToGivenQuery,
    /**
     * @public
     * borderColour () is a function that returns the border colour for the selected query in Query Modal.
     * @param {number or string} queryId - Query / Record Id
     * @returns {string} colour of the border in rgba formatting
     */
    borderColour (queryId) {
      const indexOfQuery = this.selectedQueries.findIndex(
        query => parseInt(query.queryId) === parseInt(queryId)
      )
      const defaultColourSetKey = this.$DASHBOARD('DEFAULT_COLOURSET_KEY')
      return this.$DASHBOARD('COLOURSET')[defaultColourSetKey].BORDER[indexOfQuery]
    },
    /**
     * @public
     * deleteSelectedQuery () is a function that removes a query from selectedQueries in the Vuex store.
     * It triggers removeSelectedQuery in store/actions to achieve that.
     * @param {number or string} queryId - Query id in String (or number)
     */
    deleteSelectedQuery (queryId) {
      this.removeSelectedQuery({ queryId: parseInt(queryId) })
    },
    /**
     * @public
     * labelTypeError () is a function that returns that border colour of InputQueries depending on whether labelType error exists.
     * It returns 'danger' when labelTypeStatus is 'true' and querySelectedStatus is 'false'
     */
    labelTypeError () {
      if (this.labelTypeStatus && !this.querySelectedStatus) {
        return 'danger'
      }
      return 'default'
    }
  }
}
</script>

<style lang="scss" scoped>
.query {
  display: flex;
  margin: 5px 0;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;

    .button-add {
      margin: 0 0 0 15px;
    }
    .spinner-icon {
      width:31.6px;
      height:30.6x;
      margin: 0 0 0 15px;
    }
  }

  .column {
    flex-grow: 0;
    margin-right: 47px;
  }

  .operator {
    flex-grow: 0;
    margin-right: 15px;
  }

  .value {
    flex-grow: 1;
    .card-body-alert {
      margin-bottom: 0;
    }
  }

  .button-delete {
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 15px;
  }
}

.query-modal {
  position: fixed;
  top: 50%;
  left: 56%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 4;
}
svg {
  width: 1em;
  height: auto;

  path {
    fill: #fff;
  }
}
</style>
