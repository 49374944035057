<template>
  <div class="dashboard" id="dashboard">
    <portal to="top-bar">
      <div class="title"><h1>Dashboard</h1></div>
      <b-button-toolbar v-data-cy="'button-toolbar'">
        <b-button-group v-if="hasPermissionForGivenAction($DASHBOARD('PERMISSIONS').ADD)" size="sm" class="mx-1">
          <b-btn v-data-cy="'new-chart-btn'" variant="success" :to="{ name: 'create-chart-builder' }">New Chart</b-btn>
        </b-button-group>
        <b-overlay :show="!hasInitialised && hasPermissionToManageCharts" spinner-small spinner-variant="secondary" rounded class="d-inline-block">
          <b-button-group v-if="hasPermissionToManageCharts" size="sm" class="mx-1">
            <b-btn v-data-cy="'manage-chart-btn'" :variant="indexState.isManage ? 'secondary' :'success'"
                   @click="toggleIsManage(); $event.target.blur()">
              {{ indexState.isManage ? 'Back to View' : 'Manage Charts' }}
            </b-btn>
          </b-button-group>
        </b-overlay>
      </b-button-toolbar>
    </portal>

    <div class="dashboard-top" v-if="hasPermissionForGivenAction($DASHBOARD('PERMISSIONS').VIEW)">
      <b-spinner v-if="!hasInitialisedInitial" class="loader" variant="secondary" large label="Loading..." />
      <div v-else class="controls">
        <b-tabs
          v-data-cy="'group-tab-bar'"
          v-if="indexState.isManage"
          v-model="indexState.activeTabIdx"
          nav-class="font-weight-bold"
          pills
          card
        >
          <b-tab
            :id="`tab-${idx}`"
            v-for="(group, idx) in consolidatedTabs"
            :key="group.group_id"
            :title="group.group_name"
            @click="changeTab(group.group_id, idx)"
          />
        </b-tabs>
        <search-sort-controls
          v-data-cy="'search-and-sort-controls'"
          class="search-and-sort-controls"
          :loading="partialRefreshing"
          :showSortAndRefresh="true"
          :hasClearSearchCross="true"
          :hasAdvancedSearch="false"
          :hasModelSelection="false"
          :currentSentSearch="indexState.params.search"
          :searchCount="chartAPIDetails.count"
          :searchNoun="$DASHBOARD('MODELS').CHARTS_CAP"
          :sortFields="orderingFields"
          :isForEntityOrLink="false"
          :initialSortText="indexState.lastSortText"
          sortCustomIdField="id"
          sortCustomDateTimeField="created_on"
          @sort="sortCharts"
          @search="searchCharts"
          @refresh="fetch(false, true)"
          @lastSelectedSortText="setLatestSortText"
        />
      </div>
    </div>

    <div v-if="hasInitialisedInitial && !isEmpty && !partialRefreshing" class="results-area">
      <chart-cards :charts="viewableCharts" :has-actions="indexState.isManage" :style="{ visibility: chartCardsVisibility }">
        <template v-slot:title="{ chart }">
          <b-link v-b-toggle="`accordion-${ chart.id }`" v-b-tooltip:dashboard.hover="'Click to show/hide chart details'">
            <strong v-encode-highlighted-html="checkIfTextInSearch(chart.title)" />
            <span><ArrowDown class="arrow-icon-down"/></span>
            <span><ArrowDown class="arrow-icon-up"/></span>
          </b-link>
        </template>

        <template v-if="hasPermissionForGivenAction($DASHBOARD('PERMISSIONS').CHNG)" v-slot:edit="{ chart }">
          <b-btn variant="success"
             v-b-tooltip:dashboard.hover
             :title="$LOCAL('ToolTipLabelEdit')"
             :to="{ name: 'edit-chart-builder', params: { id: chart.id }}">
            <LaEdit height="20px"/>
          </b-btn>
        </template>

        <template v-if="hasPermissionForGivenAction($DASHBOARD('PERMISSIONS').DELETE)" v-slot:delete="{ chart }">
          <b-btn v-b-tooltip:dashboard.hover :title="$LOCAL('ToolTipLabelDelete')"
                 @click="deleteThisChart(chart)" variant="danger">
            <LaTrash height="20px"/>
          </b-btn>
        </template>

        <template v-slot:fields-and-queries="{ chart }">
          <b-collapse :id="`accordion-${ chart.id }`" class="accordion" role="tabpanel">
            <br />
            <b-card>
              <table
                class="fields-table mt-1"
                v-for="(table, idx) in fields"
                :key="idx"
                :aria-labelledby="`fields-table-${idx}`"
                :aria-describedby="`fields-table-${idx}`"
              >
                <tr class="fields-table-header-row">
                  <th class="fields-col" v-for="field in table" :key="field.label" scope="col">
                    <strong>
                      {{field.label}}
                      <component class="sort-arrow" :is="fieldSortIcon(field.value)"/>
                    </strong>
                  </th>
                </tr>
                <tr class="fields-table-cell-row">
                  <td class="fields-col" v-for="field in table" :key="field.label">
                    <span v-if="field.value == 'label_type' && chart[field.value] == $DASHBOARD('LABEL_TYPE').FIELD.VALUE">
                      {{ $DASHBOARD('LABEL_TYPE').FIELD.DISPLAY }}:
                      <b-badge class="badge-pill" v-if="chartHasBrokenQueries(chart) || !hasPermissionToViewQueries || !hasAccessGroupToAllQueriesInChart(chart)" pill variant="danger">error</b-badge>
                      <b-overlay v-else :show="!chartHasGeneratedDetails(chart)" spinner-small spinner-variant="secondary" spinner-type="grow" rounded class="d-inline-block">
                        <em v-if="!chartHasGeneratedDetails(chart)">FIELD LOADING</em>
                        <em v-else v-encode-highlighted-html="checkIfTextInSearch(chart.generated_details.field_label)" />
                      </b-overlay>
                    </span>
                    <span v-else-if="field.value === 'publish_to'">
                      <b-badge class="badge-pill" pill :variant="getPublishStatusVariant(chart[field.value])">
                        {{ getPublishStatus(chart[field.value]) }}
                      </b-badge>
                      <br/>
                      {{ getGroupNames(chart[field.value]) }}
                    </span>
                    <span v-else v-encode-highlighted-html="checkIfTextInSearch(getLabelValue(chart[field.value], field.value))"/>
                  </td>
                </tr>
              </table>

              <hr/>

              <table class="queries-table">
                <caption class="queries-table-caption">
                  Queries selected
                  <b-badge class="badge-pill" v-if="chartHasBrokenQueries(chart) || !hasPermissionToViewQueries || !hasAccessGroupToAllQueriesInChart(chart)" pill variant="danger">error</b-badge>
                </caption>
                <b-overlay v-if="!chartHasBrokenQueries(chart) && hasPermissionToViewQueries && hasAccessGroupToAllQueriesInChart(chart)" :show="!chartHasGeneratedDetails(chart)" spinner-small spinner-variant="secondary" spinner-type="grow" rounded class="d-inline-block overlay-queries">
                  <div v-if="!chartHasGeneratedDetails(chart)">
                    <tr v-for="(dataset, index) in chart.c_datasets" :key="dataset.id" class="queries-table-row">
                      <th scope="row" class="queries-table-index"><strong>{{index+1}}</strong></th>
                      <td class="queries-table-name">
                        LOADING QUERY
                      </td>
                    </tr>
                  </div>
                  <div v-else>
                    <tr v-for="(query, index) in chart.generated_details.query_details" :key="query.query_id" class="queries-table-row">
                      <th scope="row" class="queries-table-index"><strong>{{index+1}}</strong></th>
                      <td class="queries-table-name">
                        <router-link class="router-link" :to="{ name: 'generate-query', params: { id: query.query_id } }">
                          {{query.query_name}}
                        </router-link>
                      </td>
                    </tr>
                  </div>
                </b-overlay>
              </table>
            </b-card>
          </b-collapse>
        </template>

        <template v-slot:graphic="{ chart }">
          <b-alert v-if="!hasPermissionToViewQueries || !hasAccessGroupToAllQueriesInChart(chart)" class="graphic-error" show variant="danger">
            <div class="d-flex flex-row justify-content-center">
              <la-warning class="warning-icon" />
            </div>
            {{$DASHBOARD('NO_PERMS_ERROR_MESSAGE').QUERIES.VIEW_NO_ACCESS_GROUPS}}
          </b-alert>
          <b-alert v-else-if="chartHasBrokenQueries(chart)" class="graphic-error" show variant="danger">
            <div class="d-flex flex-row justify-content-center">
              <la-warning class="warning-icon" />
            </div>
            {{$DASHBOARD('VIEWS').INDEX.ERROR_MESSAGE.DELETED_OR_CHANGED_QUERY_IN_CHART}}
          </b-alert>
          <div class="graphic-container" v-else>
            <b-spinner v-if="!chartHasGeneratedDetails(chart) && !individualChartError(chart)" variant="secondary" large label="Loading..." />
            <error-handler-dashboard
              v-else-if="!chartHasGeneratedDetails(chart) && individualChartError(chart)"
              :error="individualChartError(chart)"
              :errorMessage="individualChartError(chart)"
              variant="danger"
            />
            <div class="chart-container" v-else>
              <component
                class="chart-rendering"
                :is="getReformattedComponentName(chart.chart_type)"
                :rawChartData="chart"
                :colourSet="chart.colour_set"
              />
            </div>
          </div>
        </template>

        <template v-slot:info="{ chart }">
          <b-link v-if="!shouldDisplayLegend(chart)" class="inactive-link">
            <la-info-circle
              class="info-circle"
              v-b-tooltip:dashboard.hover.rightbottom
              :title="$DASHBOARD('CHART_MESSAGE').LEGENDS_AND_LABELS_HAVE_BEEN_HIDDEN"
            />
          </b-link>
        </template>

        <template v-slot:star="{ chart }">
           <star :id="chart.id + '-star'" height="20px"
                v-b-tooltip:dashboard.hover :title="$LOCAL('ToolTipLabelStar')"
                class="star-icon cursor-pointer" @click="dashboardToggleFavourite(chart.id)"/>
        </template>
      </chart-cards>

      <div v-if="hasInitialisedInitial && viewableCharts.length < chartAPIDetails.count" class="load-more">
        <!-- <b-button class="load-more-button" variant="outline" @click="loadMore()" :disabled="hasChartsWithNoGeneratedDetailsAndAreNotBroken"> -->
        <b-button class="load-more-button" variant="outline" @click="loadMore()">
          <b-badge class="badge-pill" v-if="appendLoading === false" pill variant="secondary" >{{$LOCAL('LoadMore')}}</b-badge>
          <b-spinner v-else small label="Loading"/>
        </b-button>
      </div>
    </div>

    <delete-modal item="" :itemId="chartToDeleteTitle.toString()" :modalShow="showDeleteModal" @ok="processDelete"
                  @hide="cancelDelete" ref="deleteModal">
    </delete-modal>

    <!-- <error-handler
      v-if="error !== false && loading === false"
      :error="displayError"
      :showError="showError"
      variant="danger"
      @dismiss-error="showError = false"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { find } from 'lodash'
import LaInfoCircle from '@/assets/la-info-circle.svg'
import ErrorHandler from '../../insight/components/ErrorHandler' // TODO: Change to dashboard one
import ErrorHandlerDashboard from '@/modules/dashboard/components/ErrorHandler' // TODO: Error handler change to this post v2.1 (pls add the dismiss button also)
import ChartCards from '../components/ChartCards'
import SingleBarChart from '../components/chart/SingleBarChart'
import PieChart from '../components/chart/PieChart'
import MultiBarChart from '../components/chart/MultiBarChart'
import ArrowDown from '@/assets/angle-up-solid.svg'
import LaTrash from '@/assets/la-trash.svg'
import LaEdit from '@/assets/la-edit.svg'
import LaRefresh from '@/assets/la-refresh.svg'
import LaSortAsc from '@/assets/la-sort-asc.svg'
import LaSortDown from '@/assets/la-sort-desc.svg'
import DeleteModal from '../../insight/components/DeleteModal'
import DashboardUtils from '@/modules/dashboard/utils'
import ToastMessage from '@/utils/toast_message'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'
import SearchDisplay from '@/modules/insight/components/search_sort/SearchDisplay'
import Star from '@/assets/star-solid.svg'
import LaWarning from '@/assets/la-warning.svg'
import FavouriteMixin from '@/modules/forms/mixins/FavouriteMixin'
import NavigationCheckMixin from '@/modules/dashboard/mixins/NavigationCheckMixin'

import '@/dependencies/chart_dependencies'
import moment from 'moment'
import Constants from '@/constants/constants'

export default {
  name: 'dashboard',
  components: {
    LaInfoCircle,
    ChartCards,
    ErrorHandler,
    SingleBarChart,
    PieChart,
    MultiBarChart,
    ArrowDown,
    LaTrash,
    LaEdit,
    LaRefresh,
    LaSortAsc,
    LaSortDown,
    DeleteModal,
    SearchSortControls,
    SearchDisplay,
    Star,
    LaWarning,
    ErrorHandlerDashboard
  },
  mixins: [FavouriteMixin, NavigationCheckMixin],
  data: () => ({
    loading: false,
    appendLoading: false,
    partialRefreshing: false,
    chartToDeleteId: '',
    chartToDeleteTitle: '',
    showDeleteModal: false,
    showError: false,
    displayError: {
      code: -1,
      message: ''
    },
    chartError: false
  }),
  computed: {
    ...mapState('dashboard', {
      orderingFields: 'orderingFields',
      chartAPIDetails: 'chartAPIDetails',
      viewableCharts: 'viewableCharts',
      error: 'error',
      indexState: 'indexState',
      chartIndexError: 'chartIndexError'
    }),
    ...mapGetters('dashboard', {
      hasPermissionForGivenAction: 'hasPermissionForGivenAction'
    }),
    ...mapGetters('auth', {
      consolidatedTabs: 'consolidatedTabs'
    }),
    ...mapState('auth', {
      userData: 'userData'
    }),
    ...mapState('insight/queries', {
      queryCRUDPermissions: 'queryCRUDPermissions'
    }),
    isEmpty () {
      return this.viewableCharts.length === 0
    },
    hasInitialisedInitial () { // for very first page load
      return typeof this.viewableCharts === 'object' && !this.loading
    },
    hasInitialised () {
      return this.hasInitialisedInitial && !this.appendLoading && !this.partialRefreshing
    },
    chartCardsVisibility () {
      return this.loading ? 'hidden' : 'visible'
    },
    hasPermissionToManageCharts () {
      // true as long as there is access to VIEW AND EITHER change or delete
      return this.hasPermissionForGivenAction(this.$DASHBOARD('PERMISSIONS').VIEW) && (this.hasPermissionForGivenAction(this.$DASHBOARD('PERMISSIONS').CHNG) || this.hasPermissionForGivenAction(this.$DASHBOARD('PERMISSIONS').DELETE))
    },
    hasPermissionToViewQueries () {
      return this.queryCRUDPermissions.includes(`insight.${this.$DASHBOARD('PERMISSIONS').VIEW}_ibasedatastore`)
    },
    hasChartsWithNoGeneratedDetailsAndAreNotBroken () {
      if (!this.viewableCharts) { // false or undefined
        return false
      } else {
        return this.viewableCharts.some(chart => ((typeof chart.generated_details === 'string') && (!this.chartHasBrokenQueries(chart))))
      }
    },
    curSortBy () {
      return this.indexState.params.ordering.split(',')[0]
    },
    splitSearchTerms () {
      const termArray = this.indexState.params.search.trim().split(' ').filter(term => term !== '')
      return termArray.map(term => term.replace(/\\/g, '\\\\')) // to escape any backslashes
    },
    fields () {
      return this.$DASHBOARD('VIEWS').INDEX.DETAIL_FIELDS
    }
  },
  watch: {
    hasInitialised: {
      handler: async function (newHasInitialised) {
        // basic chart details ready, so move on to generate chart details
        if (newHasInitialised && typeof this.viewableCharts[0] === 'object' && this.hasChartsWithNoGeneratedDetailsAndAreNotBroken) {
          // check if user has permissions to view queries before generating chart details.
          // If yes, generate chart details
          // If no, set unauthorised error and do not generate details
          if (this.hasPermissionToViewQueries) {
            for (const chart of this.viewableCharts) {
              if (!this.chartHasBrokenQueries(chart)) { // double check that we update only if chart has no broken queries
                this.updateViewableChartsWithSingleChart({ chartId: chart.id })
              }
            }
          } else {
            // Note: no errorDetails passed in, since this is programmatically called from the frontend
            this.setUnauthorisedDashboardErrorForGivenAction({ action: this.$DASHBOARD('PERMISSIONS').VIEW, model: this.$DASHBOARD('MODELS').QUERIES })
          }
        } // if newHasInitialised changed to false, do nothing
      }
    },
    error: {
      handler: function (newError) {
        if (newError === false) {
          // reset local error-relevant data
          this.showError = false
          this.displayError = {
            code: -1,
            message: ''
          }
        } else {
          // populate local error-relevant data
          this.displayError.message = `Error(s) detected while building your chart(s): [${newError.message}]. Please resolve them and try again.`
          this.displayError.code = newError.code
          this.showError = true
        }
      },
      deep: true
    }
  },
  async mounted () {
    await this.getDashboardPermissions() // populates dashboard vuex state
    await this.getQueryCRUDPermissions() // populates query vuex state
    await this.retrieveUserData() // populates auth vuex state
    if (this.hasPermissionForGivenAction(this.$DASHBOARD('PERMISSIONS').VIEW)) {
      await this.fetch()
      await this.getOrderingFields() // populates dashboard vuex state
    } else {
      // Note: no errorDetails passed in, since this is programmatically called from the frontend
      this.setUnauthorisedDashboardErrorForGivenAction({ action: this.$DASHBOARD('PERMISSIONS').VIEW, model: this.$DASHBOARD('MODELS').CHARTS })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.checkNavigationWithinDashboardAndReset(to, from, next)
  },
  beforeDestroy () {
    this.resetViewableCharts()
  },
  methods: {
    ...mapActions('dashboard', [
      'getDashboardPermissions',
      'getOrderingFields',
      'setUnauthorisedDashboardErrorForGivenAction',
      'getAllViewableChartsOptimised',
      'resetViewableCharts',
      'updateViewableChartsWithSingleChart',
      'deleteChart'
    ]),
    ...mapActions('insight/queries', [
      'getQueryCRUDPermissions'
    ]),
    ...mapActions('auth', [
      'retrieveUserData'
    ]),
    ...mapMutations('dashboard', [
      'toggleIsManageState',
      'setActiveTabIdxState',
      'incrementPageNum',
      'resetPageNum',
      'setOrdering',
      'setSearch',
      'setGroupId',
      'setLastSortText'
    ]),
    chartHasBrokenQueries: DashboardUtils.chartHasBrokenQueries,
    shouldDisplayLegend: DashboardUtils.shouldDisplayLegend,
    hasAccessGroupToGivenQuery: DashboardUtils.hasAccessGroupToGivenQuery,
    async fetch (append = false, partialRefresh = false) {
      if (this.loading === false && this.appendLoading === false) {
        if (append) {
          this.appendLoading = true
          await this.getAllViewableChartsOptimised({ params: this.indexState.params, append: true })
          this.appendLoading = false
        } else {
          await this.resetPageNum()
          if (partialRefresh) {
            this.partialRefreshing = true
            await this.getAllViewableChartsOptimised({ params: this.indexState.params, append: false })
            this.partialRefreshing = false
          } else {
            this.loading = true
            await this.getAllViewableChartsOptimised({ params: this.indexState.params, append: false })
            this.loading = false
          }
        }
        // Calling favourite mixin method
        await this.checkFavourited(this.viewableCharts, this.$DASHBOARD('FAVOURITE').TYPE)
      }
      if (this.error) {
        this.loading = false
      }
    },
    loadMore () {
      if (this.loading === false && this.appendLoading === false) {
        this.incrementPageNum()
        this.fetch(true, false)
      }
    },
    sortCharts (sortBy) {
      this.setOrdering(sortBy.join(','))
      this.fetch(false, true)
    },
    fieldSortIcon (field = '') {
      let icon = LaSortAsc
      let curSortByClone = this.curSortBy
      if (curSortByClone.startsWith('-')) { // Check if descending sort
        curSortByClone = curSortByClone.slice(1)
        icon = LaSortDown
      }
      return field === curSortByClone && this.hasInitialisedInitial ? icon : ''
    },
    setLatestSortText (latestSortText) { // to maintain state of lastSortText in indexState
      if (!this.noMoreChangesToIndexStateShouldBeMade) {
        this.setLastSortText(latestSortText)
      }
    },
    searchCharts (searchTerm) {
      this.setSearch(searchTerm)
      this.fetch(false, true)
    },
    hasAccessGroupToAllQueriesInChart (chart) {
      if (!this.chartHasGeneratedDetails(chart)) {
        return true // ensure that overlays spin first before we can properly determine the access right
      }

      for (const queryDetail of chart.generated_details.query_details) {
        if (!this.hasAccessGroupToGivenQuery(this.userData.user_access_groups, queryDetail.access_group)) {
          return false
        }
      }
      return true // requires user to have access to ALL queries
    },
    getGroupNames (groupIds = []) {
      // compare each group id to user's permitted access groups. If they exist, show the group name
      if (groupIds.length === 0) {
        return ''
      }

      let groupNames = ''
      for (const group of this.userData.user_groups) {
        if (groupIds.includes(group.group_id)) {
          groupNames = groupNames.concat(`${group.group_name} | `)
        }
      }
      return groupNames.slice(0, -3) // removes last ' | ' (3 chars)
    },
    getPublishStatus (groupIds = []) {
      return groupIds.length === 0
        ? this.$DASHBOARD('VIEWS').INDEX.PUBLISH_STATUS.PRIVATE
        : this.$DASHBOARD('VIEWS').INDEX.PUBLISH_STATUS.PUBLISHED
    },
    getPublishStatusVariant (groupIds = []) {
      return groupIds.length === 0 ? 'secondary' : 'success'
    },
    getLabelValue (stringToFormat, fieldType = '') {
      if (stringToFormat === this.$DASHBOARD('LABEL_TYPE').FIELD.VALUE) { // FIELD
        return this.$DASHBOARD('LABEL_TYPE').FIELD.DISPLAY
      } else if (stringToFormat === this.$DASHBOARD('LABEL_TYPE').OUTPUT_MODEL_NAME.VALUE) { // OUTPUT_MODEL_NAME
        return this.$DASHBOARD('LABEL_TYPE').OUTPUT_MODEL_NAME.DISPLAY
      }

      if (typeof stringToFormat === 'string' || typeof stringToFormat === 'number') {
        stringToFormat = stringToFormat.toString()
        if (this.$DASHBOARD('VIEWS').INDEX.EXCLUDE_FORMATTING_LABEL_FIELDS.includes(fieldType)) {
          return stringToFormat
        } else if (this.$DASHBOARD('VIEWS').INDEX.TIME_FIELDS.includes(fieldType)) {
          return moment(stringToFormat).format('L LTS')
        }
        // Default return format: Takes in a string or number e.g. "SINGLE_BAR" and returns "Single Bar"
        return stringToFormat
          .split('_')
          .map(word => word.toLowerCase())
          .map(word => word.substring(0, 1).toUpperCase() + word.substring(1))
          .join(' ')
      } else {
        // Not a string or number, return stringified representation
        return JSON.stringify(stringToFormat)
      }
    },
    getReformattedComponentName (stringToFormat) {
      // Takes in a string "PIE"/"SINGLE_BAR"/"MULTI_BAR" and returns "pie-chart"/"single-bar-chart"/"multi-bar-chart"
      return stringToFormat
        .split('_')
        .join('-')
        .toLowerCase()
        .concat('-chart')
    },
    chartHasGeneratedDetails (chart) {
      return typeof chart.generated_details === 'object'
    },
    toggleIsManage () {
      if (this.indexState.isManage) {
        this.changeTab(this.$DASHBOARD('GROUPS').FAVOURITES.group_id,
          this.$DASHBOARD('GROUPS').FAVOURITES.tab_idx) // back to favourites tab
      }
      this.toggleIsManageState()
    },
    dashboardToggleFavourite (chartId) {
      // call favouritemixin method to change html and remove from userFavouriteList
      this.toggleFavourite(this.$DASHBOARD('FAVOURITE').TYPE, chartId)

      // If on Favourites page, refresh view partially to update chart view
      if (this.indexState.activeTabIdx === this.$DASHBOARD('GROUPS').FAVOURITES.tab_idx) {
        this.fetch(false, true)
      }
    },
    changeTab (groupId, idx) {
      if (this.indexState.activeTabIdx !== idx) {
        this.setGroupId(groupId)
        this.setActiveTabIdxState(idx) // update v-model with tabidx by calling mutation
        this.fetch(false, true)
      }
    },
    deleteThisChart (chart) {
      if (chart.id) {
        this.chartToDeleteId = chart.id
        this.chartToDeleteTitle = chart.title
        this.showDeleteModal = true
      }
    },
    cancelDelete () {
      this.showDeleteModal = false
    },
    async processDelete () {
      // set page to partialRefresh (We don't set to loading bc it will cause hasInitialisedInitial to return false
      // which we will detroy the searchForm component)
      this.partialRefreshing = true

      // Delete chart in BE
      const deleteResponseStatus = await this.deleteChart({ chartId: this.chartToDeleteId })

      // Set up toast
      let toastText
      const toastMeta = {
        variant: '',
        title: ''
      }
      const h = this.$createElement

      if (deleteResponseStatus === true) {
        // Remove from fav - favouritemixin
        await this.unFavouriteThis(this.$DASHBOARD('FAVOURITE').TYPE, this.chartToDeleteId)
        ToastMessage.showDeletedSuccess({
          vueInstance: this,
          name: this.chartToDeleteTitle
        })
      } else {
        toastText = h('p', { class: ['mb-0'] },
          [
            `${this.$DASHBOARD('VIEWS').INDEX.ERROR_MESSAGE.DELETING_CHART} `,
            h('strong', {}, this.chartToDeleteTitle)
          ]
        )
        toastMeta.variant = 'danger'
        toastMeta.title = 'Error'
        // Trigger toast
        this.$root.$bvToast.toast(toastText, {
          variant: toastMeta.variant,
          title: toastMeta.title,
          autoHideDelay: 5000,
          appendToast: true
        })
      }

      // Clean up and refresh
      this.partialRefreshing = false
      await this.fetch(false, true)
    },
    /**
     * @public
     * Checks if the given fieldValue contains any search terms (mirroring backend search process)
     * @param fieldValue {String || Number} Defaults to empty string
     * @returns {String} HTML implementation of the fieldValue
     */
    checkIfTextInSearch (text = '') {
      if (!text || !this.indexState.params.search) {
        return text
      } else {
        // Get all non-empty search terms, split by spaces
        if (!this.splitSearchTerms || this.splitSearchTerms.length === 0) {
          return text
        } else {
          // Sort search terms according to length of each term (ascending)
          this.splitSearchTerms.sort((term1, term2) => (term2.length - term1.length))
          const regex = new RegExp(this.splitSearchTerms.join('|'), 'gi')
          text = text.toString().replace(regex, (match) =>
            (`${Constants.LOCAL.HTML_PATTERN.HIGHLIGHT_PREFIX}${match.toUpperCase()}${Constants.LOCAL.HTML_PATTERN.HIGHLIGHT_POSTFIX}`)
          )
          return text
        }
      }
    },
    individualChartError (chart) {
      const errorFound = find(this.chartIndexError, ['id', chart.id])
      return errorFound
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  position: relative;
}

.controls {
  padding: 0 14px 0 14px;
  position: relative;
}

.results-area {
  margin-top: -15px;
}

.fields-col {
  width: 33%;
  vertical-align: top;
}
.queries-table, .fields-table, .complex-fields-table {
  width: 100%;
}
.queries-table-row {
  display: table;
  width: 100%;
}
.queries-table-caption {
  caption-side: top;
  padding: 0px;
  color: black;
  font-weight: bold;
}
.queries-table-index {
  width: 5%;
  vertical-align: top;
}
.queries-table-name {
  width: 95%;
}
.queries-table-name:hover {
  text-decoration: underline;
}
.overlay-queries {
  width: 100%
}
.router-link {
  color: #007bff;
}
.accordion {
  width: 100%
}
.arrow-icon-down {
  height: 20px;
  padding-right: 0.5em;
  padding-top: 0.3em;
  margin-bottom: -5px;
  transform: rotate(180deg);
  transition: all 0.2s;
}
.arrow-icon-up {
  height: 20px;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  margin-bottom: -5px;
  transition: all 0.2s;
  color: #007bff;
}
.collapsed > span > .arrow-icon-up {
    display: none;
}
:not(.collapsed) > span > .arrow-icon-down {
    display: none;
}
.sort-arrow {
  height: 16px;
  width: 16px;
}
.graphic-error {
  width: 100%;
}
.graphic-container {
  display: flex;
  flex-direction: column;
  align-items: center; // vertical alignment
  justify-content: center; // horizontal alignment
  width: 100%;
  height: 100%;
  .chart-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 92%;

    .chart-rendering {
      display: flex;
      height: 95%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.inactive-link {
  cursor: default !important; // prevents cursor from switching to click sign

  .info-circle {
    height: 20px;
  }
}

.badge-pill {
  vertical-align: text-top;
}

.star-icon[data-favourite='favourite'] {
  fill: $favourited;
}

.star-icon[data-favourite='mandatory'] {
  display: none;
}

button:disabled {
  cursor: default;
}

.warning-icon {
  width: 25px;
  height: 25px;
}

</style>
