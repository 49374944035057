<template>
  <div class="results">
    <div class="record" v-for="item of items" :key="item.unique_id">
      <table class="inner shadow-sm fade-in-fast" aria-describedby="index cards">
        <thead>
          <tr class="header">
            <th class="name" scope="col">
              <slot name="name" :item="item"></slot>
            </th>

            <div class="actions">
              <b-button-group size="sm">
                <slot name="view" :item="item"></slot>
                <slot name="download" :item="item"></slot>
                <slot name="create" :item="item"></slot>
                <slot name="edit" :item="item"></slot>
                <slot name="delete" :item="item"></slot>
              </b-button-group>
            </div>
          </tr>
        </thead>

        <tbody class="fields row" >
          <tr
            class="field col-sm-3 col-md-2 col-lg-3"
            v-for="(value, field) of item" :key="item.unique_id + '-' + field"
            v-show="!hiddenFields.includes(field)"
          >
          <!-- is Below v-show condition belongs to some test? it was part of div attribute above before
          v-show="(field !== 'altentity')" -->
            <th scope="col">
              <strong>
                {{ fieldName(field) | snakeToTitle }}
                <component
                  v-bind:style="{ height: '16px', width: '16px' }"
                  :is="sortIconStatus(field)"
                />
              </strong>
              <LaInfoCircle v-if="helperText(field)" v-b-tooltip.hover :title="helperText(field)" class="info-circle" />
            </th>
            <td v-encode-highlighted-html="checkSearch(value, field)"></td>
          </tr>
        </tbody>
        <tfoot v-if="hasStar" class="row d-flex justify-content-end pr-3">
          <slot name="star" :item="item"></slot>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import LaSortAsc from '@/assets/la-sort-asc.svg'
import LaSortDown from '@/assets/la-sort-desc.svg'
import LaInfoCircle from '@/assets/la-info-circle.svg'
import Constants from '@/constants/constants'
import CurrencyStringMixin from '@/modules/insight/mixins/CurrencyStringMixin'

export default {
  name: 'index-cards',
  mixins: [CurrencyStringMixin],
  components: {
    LaSortAsc, LaSortDown, LaInfoCircle
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    fields: {
      default: () => ({})
    },
    searchQuery: {
      type: [String, null]
    },
    sortField: {
      type: Array,
      default: () => (['-create_date', 'unique_id'])
    },
    loading: {
      type: Boolean
    },
    hasStar: {
      default: false
    },
    hiddenFields: {
      default: () => ([])
    },
    helperTextData: {
      default: () => ([])
    }
  },
  data: () => ({
    sortStatus: '&darr;'
  }),
  methods: {
    fieldName (field) {
      return (this.fields[field] !== undefined) ? this.fields[field].label : field
    },
    checkSearch (value, field) {
      let date = null
      if (!value) {
        return value
      }

      if (!!field && !!this.fields[field]) {
        // To format display for currency
        if (this.fields[field].format === this.$INSIGHT('VALIDATION_FORMAT').CURRENCY) {
          value = this.currencyString(value)
        }

        // TODO Make conflict resolution strat in DateTime and use it.
        switch (this.fields[field].type) {
          case 'date':
            value = new Date(value).toDateString()
            break
          case 'time':
            value = new Date(value).toLocaleTimeString()
            break
          case 'datetime':
            date = new Date(`${value}Z`)
            value = isNaN(date) ? 'Invalid Date' : date.toUTCString().slice(0, -4)
            break
          default:
            break
        }
      }
      if (!this.searchQuery) {
        return value
      }
      const queries = this.searchQuery.trim().split(' ').filter((e) => {
        return e !== ''
      }) // Needs to be fast
      if (!value || value === '' || !queries || queries.length === 0 || queries === ['']) {
        return value
      }
      queries.sort((a, b) => {
        return b.length - a.length
      }) // Also needs to be fast
      const regex = new RegExp(queries.join('|'), 'gi') // Also needs to be fast
      value = value.toString().replace(regex, (match) => {
        return `${Constants.LOCAL.HTML_PATTERN.HIGHLIGHT_PREFIX}${match.toUpperCase()}${Constants.LOCAL.HTML_PATTERN.HIGHLIGHT_POSTFIX}`
      })
      return value
    },
    sortIconStatus (field) {
      let icon = LaSortAsc
      let sortBy = this.sortField[0]
      // Check if descending sort
      if (sortBy.startsWith('-')) {
        sortBy = sortBy.slice(1)
        icon = LaSortDown
      }
      return field === sortBy && !this.loading ? icon : ''
    },
    helperText (field) {
      let returnedValue = false
      this.helperTextData.forEach(data => {
        const keyName = Object.keys(data)[0]
        const nameSameAsField = keyName === field
        if (nameSameAsField) {
          returnedValue = data[keyName]
        }
      })
      return returnedValue
    }
  }
}
</script>

<style lang="scss" scoped>
// Remove default stylings
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

th, td {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.results {
  display: flex;
  flex-wrap: wrap;

  .record {
    padding: 14px;
    text-align: left;
    flex-basis: 100%;

    .inner {
      background: #FFF;
      border: 1px solid rgba(0, 0, 0, 0.125);
      flex-grow: 1;
      align-self: stretch;
      padding: 20px;
    }

    .header {
      display: flex;

      .name {
        font-size: 1em;
        font-weight: bold;
        flex-grow: 1;
        // TODO Abstract to default text color

        a {
          color: black;
        }
      }
    }

    .fields {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      margin: -5px;
      font-size: 0.9em;

      .field {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 5px;
      }
    }
  }
}

.info-circle {
  height: 18px;
  margin: 0px 0px 5px -3px;
}
</style>
