<template>
  <div class="content">
    <portal to="top-bar">
      <div class="title" v-if="this.$route.meta.action===$DASHBOARD('ROUTE_ACTION').CREATE"><h1>{{ $DASHBOARD('VIEWS').CHARTBUILDER.CREATE_CHART }}</h1></div>
      <div class="title" v-if="this.$route.meta.action===$DASHBOARD('ROUTE_ACTION').EDIT"><h1>{{ $DASHBOARD('VIEWS').CHARTBUILDER.EDIT_CHART }}</h1></div>
    </portal>

    <b-overlay :show="submitLoading || loading " opacity="0.8">
      <wizard-container>
        <b-card v-if="!this.loading" class="va-card">
          <div class="card-header title" v-if="this.$route.meta.action===$DASHBOARD('ROUTE_ACTION').CREATE">
            <h2 class="mb-0">{{ $DASHBOARD('VIEWS').CHARTBUILDER.CREATE_CHART }}</h2>
          </div>
          <div class="card-header title" v-if="this.$route.meta.action===$DASHBOARD('ROUTE_ACTION').EDIT">
            <h2 class="mb-0">{{ $DASHBOARD('VIEWS').CHARTBUILDER.EDIT_CHART }}</h2>
          </div>
          <br />
          <b-form>
            <b-form-group
              class="sub-title mandatory"
              id="input-group-1"
              label-cols-sm="4"
              label-cols-lg="2"
              label="Chart Type*:"
              label-for="input-1"
              :state="this.chartTypeValid"
              :invalid-feedback="this.chartTypeErrorMessage"
            >
              <b-form-select
                id="input-1"
                v-model="form.chartType"
                type="text"
                :options="this.chartTypeOptions"
                :state="this.chartTypeValid"
                @change="clearFormForChartTypeChange()"
                required
              />
            </b-form-group>

            <b-form-group
              v-model="this.chartTitleErrorMessage"
              class="sub-title mandatory"
              label-cols-sm="4"
              label-cols-lg="2"
              id="input-group-2"
              label="Chart Title*:"
              label-for="input-2"
              :state="this.chartTitleValid"
              :invalid-feedback="this.chartTitleErrorMessage"
            >
              <b-form-input
                id="input-2"
                v-model="form.chartTitle"
                type="text"
                :placeholder="$DASHBOARD('VIEWS').CHARTBUILDER.PLEASE_ENTER_UNIQUE_CHART_TITLE"
                :state="this.chartTitleValid"
                :invalid-feedback="this.chartTitleErrorMessage"
                required
              ></b-form-input>
            </b-form-group>

            <div class="text-left sub-title mandatory">Queries*:</div>
            <input-queries
              class="sub-title"
              :form="form"
              :labelTypeStatus="labelTypeStatus"
              :scenario="scenario"
              :canViewQueries="hasPermissionToViewQueries"
              :userAccessGroups="userData.user_access_groups"
            />
            <div class="text-left sub-title mandatory">Data Series*:</div>
            <data-series
              :scenario="scenario"
              :form="form"
              :labelValid="labelValid"
              :labelErrorMessage="labelErrorMessage"
              :labelTypeStatus="labelTypeStatus"
              :canViewQueries="hasPermissionToViewQueries"
              :hasAccessGroupToAllQueries="hasAccessGroupToAllQueriesInChart"
              class="sub-title"
            />
            <br />
            <b-form-group
              class="text-left sub-title mandatory"
              id="input-group-3"
              label-cols-sm="4"
              label-cols-lg="2"
              label="Label Type*:"
              label-for="input-3"
              :state="this.labelTypeValid"
              :invalid-feedback="this.labelTypeErrorMessage"
            >
              <b-form-input
                :state="this.labelTypeValid"
                disabled
                id="input-3"
                v-model="form.labelType"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="text-left sub-title mandatory"
              id="input-group-4"
              label-cols-sm="4"
              label-cols-lg="2"
              label="Colour Theme:"
              label-for="input-4"
              :state="colourSetValid"
              :invalid-feedback="colourSetErrorMessage"
            >
              <b-row>
                <b-col cols="12" lg="4">
                  <b-form-select
                    id="input-4"
                    class="colourset-selection"
                    v-model="form.colourSet"
                    type="text"
                    :options="colourSetOptions"
                    :state="colourSetValid"
                    required
                  />
                </b-col>
                <b-col cols="12" lg="8">
                  <b-list-group-item disabled class="colour-palette border-0 d-flex justify-content-between align-items-center">
                    <b-badge v-for="(colour, index) in colourPalette" :key="index" variant="transparent">
                      <verte :value="colour ? colour : '#000'" model="rgb"></verte>
                    </b-badge>
                  </b-list-group-item>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <div class="chart-preview subtitle">
            <div class="text-left sub-title mandatory chart-preview-bar">
              Chart Preview:
              <b-overlay
                :show="this.previewLoading"
                spinner-small
                spinner-variant="secondary"
                rounded
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  @click="generateChartPreview(form)"
                  style="margin:5px"
                  class="preview-button"
                  :disabled="isPreviewButtonDisabled"
                >{{ this.previewButtonName }}</b-button>
              </b-overlay>
            </div>
            <div>
              <b-card class="chart-card">
                <component
                  :key="rawChartData.chart_type"
                  :is="getReformattedComponentName(chart.chart_type)"
                  :rawChartData="rawChartData"
                  :colourSet="form.colourSet"
                  class="chart-rendering chart"
                />
                <b-card-footer v-if="!shouldDisplayLegend(rawChartData)" class="icon-row">
                  <la-info-circle class="info-circle"/>
                  &nbsp;
                  <span class="info-text"><em>{{ $DASHBOARD('CHART_MESSAGE').LEGENDS_AND_LABELS_WILL_BE_HIDDEN }}</em></span>
                </b-card-footer>
              </b-card>
            </div>
          </div>
          <PublishStatus
            ref="publishStatus"
            class="publish-status-container"
            :isCreator="isCurrentUserCreator"
            :allSelectedGroups="form.publish_to"
            :userPermittedGroups="userPermittedGroups"
          />

          <ErrorHandler
            :error="error"
            :errorMessage="errorMessage"
            :missingRequiredFieldWarn="missingRequiredFieldWarn"
          />

          <div class="button-bars">
            <b-button
              type="reset"
              variant="danger"
              @click.prevent="close"
              style="margin:5px"
              class="cancel-button"
            >Cancel</b-button>
            <b-button
              :disabled="isSubmitButtonDisabled()"
              type="submit"
              variant="success"
              style="margin:5px"
              class="submit-button"
              @click="check()"
            >Submit</b-button>
          </div>

          <template v-slot:footer v-if="renderFooter">
            <system-fields :systemFields="systemFields" />
          </template>
        </b-card>
      </wizard-container>
    </b-overlay>

    <submit-modal
      :propShowModal="submitModal.showModal"
      :offendingGroups="submitModal.offendingGroups"
      :warningMessage="submitModal.message"
      @ok="processChartForm(form)"
      @hide="submitModal.showModal = false"
    />
  </div>
</template>

<script>
import InputQueries from '../components/InputQueries'
import DataSeries from '../components/DataSeries'
import { capitalize, find, cloneDeep, includes, get } from 'lodash'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import WizardContainer from '@/modules/config/components/Container'
import ErrorHandler from '@/modules/dashboard/components/ErrorHandler'
import SingleBarChart from '../components/chart/SingleBarChart'
import PieChart from '../components/chart/PieChart'
import MultiBarChart from '../components/chart/MultiBarChart'
import DashboardUtils from '@/modules/dashboard/utils'
import LaInfoCircle from '@/assets/la-info-circle.svg'
import ToastMessage from '@/utils/toast_message'
import Verte from 'verte'
import PublishStatus from '@/modules/dashboard/components/PublishStatus'
import SubmitModal from '@/modules/dashboard/components/SubmitModal'
import NavigationCheckMixin from '@/modules/dashboard/mixins/NavigationCheckMixin'
import '@/dependencies/chart_dependencies'

const SystemFields = () => import('@/components/SystemFields')

export default {
  name: 'chart-builder',
  components: {
    LaInfoCircle,
    InputQueries,
    DataSeries,
    WizardContainer,
    ErrorHandler,
    SingleBarChart,
    PieChart,
    MultiBarChart,
    Verte,
    PublishStatus,
    SubmitModal,
    SystemFields
  },
  mixins: [NavigationCheckMixin],
  data () {
    return {
      formDetails: {
        id: null,
        title: null,
        chart_type: null,
        label_type: null,
        colour_set: null,
        publish_to: [],
        c_datasets: []
      },
      form: {
        chartType: null,
        chartTitle: '',
        labelType: null,
        selectedLabel: null,
        colourSet: 'tableau.Classic10', // set default colourSet first,
        publish_to: [],
        created_by: '',
        updated_by: ''
      },
      chartTypeValid: null,
      chartTitleValid: null,
      labelTypeValid: null,
      labelValid: null,
      colourSetValid: null,
      chartTitleErrorMessage: null,
      chartTypeErrorMessage: null,
      labelTypeErrorMessage: null,
      colourSetErrorMessage: null,
      labelErrorMessage: null,
      missingRequiredFieldWarn: 'Please add all required field(s)',
      errorMessage: null,
      labelTypeStatus: false,
      chartId: null,
      chart: {
        id: 0,
        title: '',
        chart_type: '',
        label_type: '',
        c_datasets: [
          {
            query_id: 0,
            selected_output_models: [],
            field: ''
          }
        ],
        generated_details: {
          field_label: '',
          labels: [],
          values: []
        }
      },
      previewChartInitialised: false,
      loading: false,
      dataSetIds: {},
      previewLoading: false,
      submitLoading: false,
      submitModal: {
        showModal: false,
        offendingGroups: [],
        message: ''
      },
      systemFields: {}
    }
  },
  async mounted () {
    this.loading = true
    await this.getDashboardPermissions()
    await this.getQueryCRUDPermissions()
    await this.retrieveUserData()
    await this.getCreateFields()
    this.loading = false
    if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
      await this.fetch(this.$route.params.id)
    }
  },
  beforeDestroy () {
    this.clearChartBuilderPage()
  },
  beforeRouteLeave (to, from, next) {
    this.checkNavigationWithinDashboardAndReset(to, from, next)
  },
  computed: {
    ...mapState('dashboard', {
      selectedQueries: 'selectedQueries',
      storedOutputModelsAndFields: 'storedOutputModelsAndFields',
      error: 'error',
      formFields: 'formFields',
      previewButtonStatus: 'previewButtonStatus',
      indexState: 'indexState'
    }),
    ...mapGetters('dashboard', {
      seriesSelection: 'seriesSelection'
    }),
    ...mapState('insight/queries', {
      queryCRUDPermissions: 'queryCRUDPermissions'
    }),
    ...mapState('auth', {
      userData: 'userData'
    }),
    ...mapGetters('auth', {
      consolidatedTabs: 'consolidatedTabs'
    }),
    scenario () {
      const numberOfSeriesSelected = this.seriesSelection.filter(outputModel => outputModel.selected).length
      if (numberOfSeriesSelected === 1 && (this.form.chartType === this.$DASHBOARD('CHART_TYPE').PIE || this.form.chartType === this.$DASHBOARD('CHART_TYPE').SINGLE_BAR)) {
        return 1
      } else if (numberOfSeriesSelected > 1 && (this.form.chartType === this.$DASHBOARD('CHART_TYPE').PIE || this.form.chartType === this.$DASHBOARD('CHART_TYPE').SINGLE_BAR)) {
        return 2
      } else if (numberOfSeriesSelected >= 1 && (this.form.chartType === this.$DASHBOARD('CHART_TYPE').MULTI_BAR)) {
        return 3
      } else {
        return 0
      }
    },
    chartTypeOptions () {
      const types = [{ value: null, text: this.$DASHBOARD('VIEWS').CHARTBUILDER.PLEASE_SELECT_CHART_TYPE }]
      if (this.formFields.chart_type?.choices) {
        for (const element of this.formFields.chart_type.choices) {
          const obj = {}
          obj.value = element.value
          obj.text = element.display_name
          types.push(obj)
        }
      }
      return types
    },
    colourSetOptions () {
      const options = [{ value: this.$DASHBOARD('DEFAULT_COLOURSET_KEY'), text: 'Default' }]
      if (this.formFields.colour_set?.choices) {
        for (const element of this.formFields.colour_set.choices) {
          if (element.value !== this.$DASHBOARD('DEFAULT_COLOURSET_KEY')) {
            const obj = {}
            obj.value = element.value
            obj.text = element.display_name
            options.push(obj)
          }
        }
      }
      return options // array of objects
    },
    colourPalette () {
      const defaultColourSetKey = this.$DASHBOARD('DEFAULT_COLOURSET_KEY')
      return this.form.colourSet ? this.$DASHBOARD('COLOURSET')[this.form.colourSet].PALETTE : this.$DASHBOARD('COLOURSET')[defaultColourSetKey].PALETTE
    },
    rawChartData () {
      let rawChart = {}
      if (this.chart.label_type !== undefined) {
        rawChart = cloneDeep(this.chart)
      }
      return rawChart
    },
    previewButtonName () {
      return this.previewChartInitialised ? 'Refresh Chart Preview' : 'Generate Chart Preview'
    },
    hasPermissionToViewQueries () {
      return this.queryCRUDPermissions.includes(`insight.${this.$DASHBOARD('PERMISSIONS').VIEW}_ibasedatastore`)
    },
    hasAccessGroupToAllQueriesInChart () { // different implementation from the one in Index.vue
      if (this.loading) {
        return true // wait for selectedQueries to populate first
      }
      for (const query of this.selectedQueries) {
        if (!this.hasAccessGroupToGivenQuery(this.userData.user_access_groups, query.securityGroups)) {
          return false
        }
      }
      return true // user has access to all charts
    },
    isPreviewButtonDisabled () {
      return this.hasPermissionToViewQueries && this.hasAccessGroupToAllQueriesInChart ? (this.previewButtonStatus) : true
    },
    userPermittedGroups () {
      return this.userData?.user_groups ? this.userData.user_groups : []
    },
    isCurrentUserCreator () {
      if (this.userData?.username === undefined || this.form?.created_by === undefined) {
        return false // wait for username data to be populated
      }

      if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').CREATE) {
        return true
      } else {
        // edit route
        return this.userData.username === this.form.created_by
      }
    },
    renderFooter () {
      return this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT
    },
    isAllRequiredFieldNotEmpty () {
      const isScenarioTruthy = this.scenario !== 0 // refer to 'scenario' computed prop
      const isLabelSelected = this.form.selectedLabel
      const isLabelTypeIsField = this.form?.labelType === this.$DASHBOARD('LABEL_TYPE').FIELD.DISPLAY
      const conditionIfField = isScenarioTruthy && isLabelSelected
      const conditionIfSeries = isScenarioTruthy
      return isLabelTypeIsField ? conditionIfField : conditionIfSeries
    }
  },
  methods: {
    ...mapActions('dashboard', [
      'createChart',
      'clearChartBuilder',
      'getCreateFields',
      'getChartPreviewDetails',
      'updatePreviewButtonTrue',
      'updatePreviewButtonFalse',
      'getSingleChartForEdit',
      'getAllOutputModelTypesAndFieldsByQueryId',
      'updateChart',
      'addOneOutputModelTypeSelected',
      'getDashboardPermissions'
    ]),
    ...mapMutations('dashboard', [
      'updateSelectedQueries',
      'setIsManageState',
      'setActiveTabIdxState',
      'setGroupId'
    ]),
    ...mapActions('insight/queries', [
      'getQueryCRUDPermissions'
    ]),
    ...mapActions('auth', [
      'retrieveUserData'
    ]),
    chartHasBrokenQueries: DashboardUtils.chartHasBrokenQueries,
    shouldDisplayLegend: DashboardUtils.shouldDisplayLegend,
    hasAccessGroupToGivenQuery: DashboardUtils.hasAccessGroupToGivenQuery,
    /**
     * @public
     * @async
     * fetch () is a function that will retrieve form details and trigger populateSelectedQueries ().
     * It is used when route.meta.action == 'edit'.
     * @param {string or number} chartId
     */
    async fetch (chartId) {
      if (!this.loading) {
        this.loading = true
        this.formDetails.id = parseInt(chartId)
        this.chartId = parseInt(chartId)
        const response = await this.getSingleChartForEdit({ chartId: chartId })
        await this.populateFormAndSystemFields(response)
        await this.populateSelectedQueries(response)
        this.loading = false
      }
      if (this.error) {
        this.loading = false
      }
    },
    populateFormAndSystemFields (chart) {
      this.form.chartTitle = get(chart, 'title', '')
      this.form.chartType = get(chart, 'chart_type', null)
      this.form.colourSet = get(chart, 'colour_set', this.$DASHBOARD('DEFAULT_COLOURSET_KEY'))
      this.form.selectedLabel = get(chart, 'generated_details.field_label', null)
      this.form.publish_to = get(chart, 'publish_to', [])
      this.form.created_by = get(chart, 'created_by', '')
      this.form.updated_by = get(chart, 'updated_by', '')

      this.systemFields = {
        created_by: get(chart, 'created_by', ''),
        updated_by: get(chart, 'updated_by', ''),
        created_on: get(chart, 'created_on', ''),
        updated_on: get(chart, 'updated_on', '')
      }
    },
    /**
     * @async
     * populateSelectedQueries () is a function that will populate InputQueries and DataSeries component.
     * It is used when route.meta.action == 'edit'.
     * @param {Object} chart - An Object that store values of chart details retrieved directly from Backend API.
     *                 chart.generated_details - An Object that store generated_details.
     *                 chart.generated_details.field_label - field_label stores the value for selected label.
     *                 chart.generated_details.query_details[].query_id - query_id.
     */
    async populateSelectedQueries (chart) {
      if (!get(chart, 'generated_details', null) ||
        !get(chart, 'generated_details.query_details', null)) {
        return
      }

      const selectedQueriesBuild = []
      // eslint-disable-next-line no-unused-vars
      for (const eachQuery of chart.generated_details.query_details) {
        if (eachQuery.query_name === '') { // deleted query
          selectedQueriesBuild.push({
            queryId: parseInt(eachQuery.query_id), // retain query_id for key
            queryName: eachQuery.query_name, // empty query name
            securityGroups: eachQuery.access_group,
            outputModels: []
          })
        } else {
          await this.getAllOutputModelTypesAndFieldsByQueryId({ queryId: eachQuery.query_id })
          const outputModels = []
          // eslint-disable-next-line no-unused-vars
          for (const singleOutputModelAndFields of this.storedOutputModelsAndFields[eachQuery.query_id]) {
            outputModels.push({ // default set as false selected
              selected: this.isSeriesSelected(chart, singleOutputModelAndFields.om_name, eachQuery.query_id),
              type: singleOutputModelAndFields.om_type,
              name: singleOutputModelAndFields.om_name,
              queryId: parseInt(eachQuery.query_id)
            })
          }
          selectedQueriesBuild.push({
            queryId: parseInt(eachQuery.query_id),
            queryName: eachQuery.query_name,
            securityGroups: eachQuery.access_group,
            outputModels: outputModels
          })
        }
      }

      // Setting opm type for scenario 3
      if (this.scenario === 3) {
        let commonOutputModelTypeFound = false
        // eslint-disable-next-line no-unused-vars
        for (const query of selectedQueriesBuild) {
          // eslint-disable-next-line no-unused-vars
          for (const opm of query.outputModels) {
            if (opm.selected) {
              commonOutputModelTypeFound = true
              this.addOneOutputModelTypeSelected({ outputModel: opm.type })
              break
            }
          }
          if (commonOutputModelTypeFound) {
            break
          }
        }
      }
      // Set selected queries
      this.updateSelectedQueries(selectedQueriesBuild) // calling a mutation
    },
    /**
     * @public
     * isSeriesSelected () checks if a given series (identified by its outputModelName),
     * part of a chart's single query, has been selected or not.
     * @param {Object} chart
     * @param {string} outputModelName
     * @param {string or number} queryId
     * @returns {boolean} True if the series is selected.
     */
    isSeriesSelected (chart, outputModelName, queryId) {
      // eslint-disable-next-line no-unused-vars
      for (const dataset of chart.c_datasets) {
        if (parseInt(dataset.query_id) === parseInt(queryId)) {
          return dataset.selected_output_models.includes(outputModelName)
        }
      }
      return false
    },
    /**
     * @public
     * isSubmitButtonDisabled() checks if the route is edit and if there exists a single
     * non-removed deleted-broken query, returns true i.e. button should be disabled
     * @returns {boolean} True if the submit button should be disabled.
     */
    isSubmitButtonDisabled () {
      if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').CREATE) {
        return false
      } else { // edit
        // Check if there are still broken queries OR queries with no access. if true -> should disable button
        return !!this.selectedQueries.find((selectedQuery) => selectedQuery.queryName === '') || !this.hasAccessGroupToAllQueriesInChart
      }
    },
    /**
     * @public
     * getReformattedComponentName () is a function that takes in a string "PIE"/"SINGLE_BAR"/"MULTI_BAR" and
     * returns "pie-chart"/"single-bar-chart"/"multi-bar-chart"
     * @param {string} stringToFormat - A string like 'PIE', 'SINGLE_BAR' and 'MULTI_BAR'
     * @returns {string} formatted component name: "pie-chart"/"single-bar-chart"/"multi-bar-chart"
     */
    getReformattedComponentName (stringToFormat) {
      if (!stringToFormat || stringToFormat === '') {
        return 'pie-chart'
      } else {
        return stringToFormat
          .split('_')
          .join('-')
          .toLowerCase()
          .concat('-chart')
      }
    },
    /**
     * @public
     * processCDatasets () is the parent function for processScenario1Fields, processScenario2Fields and processScenario3Fields. It is used to choose the right processScenarioFields based on the scenario.
     * @param {Object} form - A form object used in chart creation.
     */
    processCDatasets (form) {
      this.clearFormDetailsCDatasets()
      if (this.scenario === 1) {
        // Extracting c_datasets for Scenario 1
        this.processScenario1Fields(form)
      }
      if (this.scenario === 2) {
        // Extracting c_datasets for Scenario 2 - form object not needed
        this.processScenario2Fields()
      }
      if (this.scenario === 3) {
        // Extracting c_datasets for Scenario 3
        this.processScenario3Fields(form)
      }
    },
    /**
     * @public
     * processScenario1Fields () is a function that processes c_datasets for Scenario 1.
     * The function will set processed c_datasets to formDetails.c_datasets.
     * @param {Object} form - A form object used in chart creation.
     *                 form.selectedLabel - The selected field for Scenario 1.
     */
    processScenario1Fields (form) {
      let dataset = {}
      const postProcessedCDataSet = []
      const selectedCandidate = this.seriesSelection.find((element) => element.selected) // Finds the single selected element in seriesSelection
      dataset.query_id = selectedCandidate.queryId
      const selectedOutputModels = []
      selectedOutputModels.push(selectedCandidate.name)
      dataset.selected_output_models = selectedOutputModels
      dataset.field = form.selectedLabel

      // edit route - setting additional dataset.chart_id and dataset.id
      if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
        dataset.chart_id = this.chartId
        if (this.dataSetIds[selectedCandidate.queryId] &&
          find(this.selectedQueries, { queryId: selectedCandidate.queryId })) {
          dataset.id = this.dataSetIds[selectedCandidate.queryId]
        }
      }

      this.formDetails.c_datasets.push(dataset)
      // Adding other queryid that have not been selected
      this.seriesSelection.forEach((eachSelection) => {
        if (
          this.formDetails.c_datasets.find(
            (element) =>
              element.query_id !== eachSelection.queryId &&
              !eachSelection.selected
          )) {
          dataset = {}
          if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
            dataset.chart_id = this.chartId
            if (
              this.dataSetIds[eachSelection.queryId] &&
              find(this.selectedQueries, { queryId: eachSelection.queryId })
            ) {
              dataset.id = this.dataSetIds[eachSelection.queryId]
            }
          }
          dataset.query_id = eachSelection.queryId
          dataset.selected_output_models = []
          dataset.field = ''
          this.formDetails.c_datasets.push(dataset)
        }
      })
      // Sort cDataSet back to original sequence
      this.selectedQueries.forEach((eachQuery) => {
        this.formDetails.c_datasets.forEach((dataset) => {
          if (dataset.query_id === eachQuery.queryId) {
            postProcessedCDataSet.push(dataset)
          }
        })
      })
      this.formDetails.c_datasets = postProcessedCDataSet
    },
    /**
     * @public
     * processScenario2Fields () is a function that processes c_datasets for Scenario 2.
     * The function will push c_datasets into formDetails.c_datasets.
     */
    processScenario2Fields () {
      this.seriesSelection.forEach((eachSelection) => {
        if (eachSelection.selected) {
          // ONLY THOSE MODELS THAT ARE SELECTED WILL ENTER THIS LOOP
          const dataset = {}
          dataset.query_id = eachSelection.queryId
          if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
            dataset.chart_id = this.chartId
            if (
              this.dataSetIds[eachSelection.queryId] &&
              find(this.selectedQueries, { queryId: eachSelection.queryId })
            ) {
              dataset.id = this.dataSetIds[eachSelection.queryId]
            }
          }
          if (this.formDetails.c_datasets.find((element) => element.query_id === dataset.query_id)) {
            this.formDetails.c_datasets
              .find((element) => element.query_id === dataset.query_id)
              .selected_output_models.push(eachSelection.name)
          } else {
            dataset.selected_output_models = []
            dataset.selected_output_models.push(eachSelection.name)
            dataset.field = ''
            this.formDetails.c_datasets.push(dataset)
          }
        } else {
          // storing unselected queries into c_datasets
          if (!find(this.formDetails.c_datasets, { query_id: eachSelection.queryId })) {
            const dataset = {}
            dataset.query_id = eachSelection.queryId
            if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
              dataset.chart_id = this.chartId
            }
            dataset.selected_output_models = []
            dataset.field = ''
            this.formDetails.c_datasets.push(dataset)
          }
        }
      })
    },
    /**
     * @public
     * processScenario3Fields () is a function that processes c_datasets for Scenario 3. The function will push c_datasets into formDetails.c_datasets.
     * @param {Object} form - A form object used in chart creation.
     * @param {string} form.selectedLabel - The selected field for Scenario 3.
     */
    processScenario3Fields (form) {
      // ORIGINAL
      let selectedLabel = form.selectedLabel
      const cDataSet = []
      const postProcessedCDataSet = []
      this.seriesSelection.forEach((eachSelection) => {
        if (eachSelection.selected) {
          // ONLY THOSE MODELS THAT ARE SELECTED WILL ENTER THIS LOOP
          const dataset = {}
          dataset.query_id = eachSelection.queryId

          // edit route - setting additional dataset.chart_id and dataset.id
          if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
            dataset.chart_id = this.chartId
            if (this.dataSetIds[eachSelection.queryId] && find(this.selectedQueries, { queryId: eachSelection.queryId })) {
              dataset.id = this.dataSetIds[eachSelection.queryId]
            }
          }

          if (cDataSet.find((element) => element.query_id === dataset.query_id)) {
            // LOOKING FOR EXISTING QUERY ID IN C DATASETS
            cDataSet
              .find((element) => element.query_id === dataset.query_id)
              .selected_output_models.push(eachSelection.name)
          } else {
            dataset.selected_output_models = []
            dataset.selected_output_models.push(eachSelection.name)
            dataset.field = selectedLabel
            cDataSet.push(dataset)
            selectedLabel = ''
          }
        }
      })
      this.selectedQueries.forEach((eachQuery) => {
        if (!find(cDataSet, { query_id: eachQuery.queryId })) {
          const dataset = {}
          if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
            dataset.chart_id = this.chartId
            if (
              this.dataSetIds[eachQuery.queryId] &&
              find(this.selectedQueries, { queryId: eachQuery.queryId })
            ) {
              dataset.id = this.dataSetIds[eachQuery.queryId]
            }
          }
          dataset.query_id = eachQuery.queryId // IF IT DOESNT EXIST, SLOT IN THIS METHOD . USE selectedQueries TO PROCESS THIS PART
          dataset.selected_output_models = []
          dataset.field = ''
          cDataSet.push(dataset)
        }
      })
      // Sort cDataSet back to original sequence
      this.selectedQueries.forEach((eachQuery) => {
        cDataSet.forEach((dataset) => {
          if (dataset.query_id === eachQuery.queryId) {
            postProcessedCDataSet.push(dataset)
          }
        })
      })
      this.formDetails.c_datasets = postProcessedCDataSet
    },
    getNewPublishTo () {
      // Core method to determine whats the new publish_to array from child PublishStatus component
      if (this.$refs.publishStatus.isPrivate) {
        return []
      } else {
        const initialAllSelectedGroups = this.form.publish_to
        const userPermittedGroupsIds = this.userPermittedGroups.map(x => x.group_id)
        const initialLocalSelectedGroups = new Set(userPermittedGroupsIds.filter(x => initialAllSelectedGroups.includes(x)))
        const curLocalSelectedGroups = new Set(this.$refs.publishStatus.localSelectedGroups)

        // find set difference of allgroups - initially selected local groups
        let newPublishTo = new Set([...initialAllSelectedGroups].filter(x => !initialLocalSelectedGroups.has(x)))

        // find set addition of allgroups + current selected local groups
        newPublishTo = new Set([...newPublishTo, ...curLocalSelectedGroups])

        // return as array
        return [...newPublishTo]
      }
    },
    check () {
      // Check whether to trigger submit warning modal or not
      if (this.$refs.publishStatus.offendingSelectedGroupNames.length > 0) {
        // Case 1
        this.submitModal.offendingGroups = this.$refs.publishStatus.offendingSelectedGroupNames
        this.submitModal.message = this.$DASHBOARD('COMPONENTS').SUBMITMODAL.PUBLISHED_GROUPS_NO_QUERY_ACCESS
        this.submitModal.showModal = true
      } else if (this.$refs.publishStatus.publishGroupSelectedWithNoSelectedGroups) {
        // Case 2
        this.submitModal.offendingGroups = [] // clear any existing changes
        this.submitModal.message = this.$DASHBOARD('COMPONENTS').SUBMITMODAL.EMPTY_PUBLISHED_GROUPS
        if (this.isCurrentUserCreator) {
          this.submitModal.message += this.$DASHBOARD('COMPONENTS').SUBMITMODAL.PRIVATE_OPTION_FOR_CREATOR
        }
        this.submitModal.showModal = true
      } else {
        // No issues, proceed with chart processing
        this.processChartForm(this.form)
      }
    },
    /**
     * @public
     * @async
     * processChartForm (form) is a function that processes form, c_datasets into formDetails. It will then be used for the create chart 'POST' method.
     * @param {Object} form - Form data object used in chart creation.
     */
    async processChartForm (form) {
      // trigger full overlay
      this.submitLoading = true

      // Pack all form details into this.form.formDetails then it will use POST to create chart
      this.clearErrors()
      this.clearChartFormDetails()

      // Render the form and create chart
      if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
        this.formDetails.id = this.chartId
      }
      this.formDetails.title = form.chartTitle
      this.formDetails.chart_type = form.chartType
      this.formDetails.colour_set = form.colourSet

      //  process label_type
      if (form.labelType === this.$DASHBOARD('LABEL_TYPE').FIELD.DISPLAY) {
        this.formDetails.label_type = this.$DASHBOARD('LABEL_TYPE').FIELD.VALUE
      }
      if (form.labelType === this.$DASHBOARD('LABEL_TYPE').OUTPUT_MODEL_NAME.DISPLAY) {
        this.formDetails.label_type = this.$DASHBOARD('LABEL_TYPE').OUTPUT_MODEL_NAME.VALUE
      }

      // process new publish_to
      this.formDetails.publish_to = this.getNewPublishTo()

      // process c_datasets
      await this.processCDatasets(form)

      // Create / Update
      let chart
      if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').CREATE) {
        chart = await this.createChart({ fields: this.formDetails })
      } else if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').EDIT) {
        chart = await this.updateChart({ fields: this.formDetails })
      }

      if (chart) {
        if (this.$route.meta.action === this.$DASHBOARD('ROUTE_ACTION').CREATE) {
          ToastMessage.showCreatedSuccess({
            vueInstance: this,
            name: this.form.chartTitle
          })
        } else { // meta.action = edit will enter here
          ToastMessage.showEditedSuccess({
            vueInstance: this,
            name: this.form.chartTitle
          })
        }

        // Set index state
        this.setIndexState()

        // Navigate back to Index with indexState already set
        await this.$router.push({
          path: '/dashboard/'
        })
        this.clearChartBuilderPage()
        this.clearErrors()
      }

      // Stop overlay
      this.submitLoading = false
    },
    // This method should only be called after a successful submit
    setIndexState () {
      const groups = this.formDetails.publish_to
      let activeTabIdx = this.$DASHBOARD('GROUPS').PRIVATE.tab_idx
      let groupId = this.$DASHBOARD('GROUPS').PRIVATE.group_id
      if (groups.length !== 0) {
        groupId = groups[0] // set to first selected group id
        activeTabIdx = this.consolidatedTabs.findIndex(tab => tab.group_id === groupId)
      }
      this.setIsManageState(true)
      this.setActiveTabIdxState(activeTabIdx)
      this.setGroupId(groupId)
    },
    /**
     * @public
     * @async
     * generateChartPreview () is a function used to generate chart preview.
     * It processes all the values in form and generates new c_datasets
     * @param {Object} form
     *                 form.chartTitle - A string that stores the chart title.
     *                 form.chartType - A string that stores the chart type.
     *                 form.labelType - A string that stores the label type.
     */
    async generateChartPreview (form) {
      this.validateFieldGCP()
      if (!this.isAllRequiredFieldNotEmpty) {
        ToastMessage.showErrorDefault({
          vueInstance: this,
          textMessage: this.$DASHBOARD('VIEWS').CHARTBUILDER.ERROR_MESSAGE.EMPTY_REQUIRED_FIELD
        })
      } else if (this.previewLoading === false) {
        this.previewLoading = true
        this.clearChart()
        this.previewChartInitialised = true
        const chartPreviewData = {}
        chartPreviewData.chart_type = form.chartType
        if (form.labelType === this.$DASHBOARD('LABEL_TYPE').FIELD.DISPLAY) { // Field
          chartPreviewData.label_type = this.$DASHBOARD('LABEL_TYPE').FIELD.VALUE // FIELD
        }
        if (form.labelType === this.$DASHBOARD('LABEL_TYPE').OUTPUT_MODEL_NAME.DISPLAY) { // Series
          chartPreviewData.label_type = this.$DASHBOARD('LABEL_TYPE').OUTPUT_MODEL_NAME.VALUE // OUTPUT_MODEL_NAME
        }
        this.processCDatasets(form)
        chartPreviewData.c_datasets = cloneDeep(this.formDetails.c_datasets) // chartPreviewData: chart_type: '', label_type: '', c_datasets: [{ query_id: '', selected_output_models: [''], field: '' }]
        const generatedDetails = await this.getChartPreviewDetails({
          data: chartPreviewData
        })
        this.chart.generated_details = cloneDeep(generatedDetails)
        this.chart.c_datasets = cloneDeep(this.formDetails.c_datasets)
        this.chart.chart_type = chartPreviewData.chart_type
        this.chart.label_type = chartPreviewData.label_type
        this.previewLoading = false
        this.updatePreviewButtonTrue()
      }
      if (this.error) {
        this.previewLoading = false
      }
    },
    /**
     * @public
     * close () is a function that triggers clearErrors(), clearChartBuilderPage() and $router.push to '/dashboard/' when 'close' button is clicked.
     */
    close () {
      this.clearErrors()
      this.clearChartBuilderPage()
      this.$router.push({
        path: '/dashboard/'
      })
    },
    /**
     * @public
     * clearFormForChartTypeChange () is a function that clears the form except for Chart Title.
     * It is triggered when chart type changes.
     * Note that this.form.labelType would have been set already so it should not be set to null here
     * (new chart type changes -> scenario changes -> this.form.labelType auto changes too)
     */
    clearFormForChartTypeChange () {
      this.form.selectedLabel = null
      this.chartTypeValid = null
      this.chartTypeErrorMessage = null
      this.clearChartBuilder()
      this.clearChart()
    },
    /**
     * @public
     * clearChartBuilderPage () is a function that clears the form, formDetails, errors in the Vuex store.
     * It is triggered when the user leaves the page or when user successfully creates / updates a chart.
     */
    clearChartBuilderPage () {
      this.clearForm()
      this.clearChartFormDetails()
      this.clearErrors()
      this.clearChartBuilder()
      this.updatePreviewButtonFalse()
      this.clearChart()
    },
    /**
     * @public
     * clearForm () is a function that clears the local form object.
     */
    clearForm () {
      this.form.chartType = null
      this.form.chartTitle = ''
      this.form.labelType = null
      this.form.selectedLabel = null
    },
    /**
     * @public
     * clearChartFormDetails () is a function that clears the local formDetails object.
     */
    clearChartFormDetails () {
      this.formDetails.title = null
      this.formDetails.chart_type = null
      this.formDetails.label_type = null
      this.formDetails.c_datasets = []
      this.formDetails.publish_to = []
    },
    /**
     * @public
     * clearErrors () is a function that clears the local/field errors and errorMessages.
     */
    clearErrors () {
      this.chartTitleValid = null
      this.chartTypeValid = null
      this.labelTypeValid = null
      this.labelValid = null
      this.chartTitleErrorMessage = null
      this.chartTypeErrorMessage = null
      this.labelTypeErrorMessage = null
      this.labelErrorMessage = null
    },
    /**
     * @public
     * clearTitleError () is a function that clears the local chartTitleValid and chartTitleErrorMessage data.
     */
    clearTitleError () {
      this.chartTitleValid = null
      this.chartTitleErrorMessage = null
    },
    /**
     * @public
     * clearLabelTypeError () is a function that clears the local labelValid and labelErrorMessage data.
     */
    clearLabelTypeError () {
      this.labelValid = null
      this.labelErrorMessage = null
    },
    /**
     * @public
     * clearLabelError () is a function that clears the local labelTypeValid and labelTypeErrorMessage data.
     */
    clearLabelError () {
      this.labelTypeValid = null
      this.labelTypeErrorMessage = null
    },
    /**
     * @public
     * clearFormDetailsCDatasets () is a function that clears formDetails.c_datasets.
     */
    clearFormDetailsCDatasets () {
      this.formDetails.c_datasets = []
    },
    /**
     * @public
     * clearChart () is a function that resets the value of chart object.
     * Chart object is used for chart preview.
     */
    clearChart () {
      this.chart = {
        id: 0,
        title: '',
        chart_type: '',
        label_type: '',
        c_datasets: [
          {
            query_id: '',
            selected_output_models: [],
            field: ''
          }
        ],
        generated_details: {
          field_label: '',
          labels: [],
          values: []
        }
      }
    },
    /**
     * Validating all of the fields, used for generating chart preview button
     */
    validateFieldGCP () {
      const errMessage = this.$DASHBOARD('VIEWS').CHARTBUILDER.ERROR_MESSAGE.FIELD_CANT_BE_BLANK
      this.chartTitleValid = !!this.form.chartTitle
      this.chartTitleErrorMessage = !this.form.chartTitle ? errMessage : ''
      this.chartTypeValid = !!this.form.chartType
      this.chartTypeErrorMessage = !this.form.chartType ? errMessage : ''
      this.labelTypeValid = !!this.form.labelType
      this.labelTypeErrorMessage = !this.form.labelType ? errMessage : ''
      this.labelTypeStatus = !this.form.labelType
      this.labelValid = !!this.form.selectedLabel
      this.labelErrorMessage = !this.form.selectedLabel ? errMessage : ''
    }
  },
  watch: {
    scenario: {
      handler (newScenario) {
        if (newScenario === 1 || newScenario === 3) {
          this.form.labelType = this.$DASHBOARD('LABEL_TYPE').FIELD.DISPLAY
        } else if (newScenario === 2) {
          this.form.labelType = this.$DASHBOARD('LABEL_TYPE').OUTPUT_MODEL_NAME.DISPLAY
        } else {
          this.form.labelType = ''
        }
      }
    },
    error: {
      handler (error) {
        if (error.code === 404) {
          this.$router.push({ path: '/dashboard/' })
        } else {
          if (error?.organicDetails?.title) {
            this.chartTitleValid = false
            this.chartTitleErrorMessage = capitalize(error.organicDetails.title)
          }

          if (error?.organicDetails?.chart_type) {
            this.chartTypeValid = false
            this.chartTypeErrorMessage = capitalize(error.organicDetails.chart_type)
          }

          if (error?.organicDetails?.label_type) {
            this.labelTypeValid = false
            this.labelTypeErrorMessage = this.$DASHBOARD('VIEWS').CHARTBUILDER.ERROR_MESSAGE.LABEL_TYPE
            this.labelTypeStatus = true
          }

          if (error?.organicDetails?.c_datasets) {
            this.labelValid = false
            this.labelErrorMessage = capitalize(error.organicDetails.c_datasets[0].field)
          }

          // Without error titles
          if (error?.organicDetails) {
            if (includes(error.organicDetails, 'generating preview chart')) {
              // preview generation error, cannot take from auto-formatted message as it confuses with chart creation
              this.errorMessage = error.organicDetails + this.$DASHBOARD('VIEWS').CHARTBUILDER.ERROR_MESSAGE.CHART_PREVIEW_APPEND_ERROR
            } else { // for 403 errors, error message would have been set in actions createChart, updateChart
              this.errorMessage = error.message
            }
          }
        }
      },
      deep: true
    },
    form: {
      handler (form) {
        if (form?.chartTitle) {
          this.clearTitleError()
        }
        if (form?.labelType) {
          this.clearLabelTypeError()
          this.clearLabelError()
          this.labelTypeStatus = false
        }
        if (form?.selectedLabel) {
          this.clearLabelError()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/verte/dist/verte.css';

.query-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.sub-title {
  padding-right: 15px;
  padding-left: 15px;
}
.content {
  text-align: left;
}

.va-card {
  width: 85%;
  margin: auto;
  .card-body {
    padding: 0;
  }
  .chart-card {
    width: 96.5%;
    height: 96.5%;
    margin-left: auto;
    margin-right: auto;

    .chart {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      height: 50%;
    }

    .icon-row {
      .info-circle {
        vertical-align: middle;
        height: 20px;
      }

      .info-text {
        vertical-align: middle;
      }
    }
  }
}

.chart-rendering {
  height: 90%;
  width: 90%;
}

.chart-container {
  width: 50%;
  height: 50%;
}

.button-bars {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.chart-preview-bar {
  display: flex;
  justify-content: space-between;
  padding-left: 14px;
  padding-right: 10px;
}
.submit-button {
  flex-grow: 0;
}

.close-button {
  flex-grow: 0;
  align-self: flex-start;
}

.preview-button {
  flex-grow: 0;
}

.mandatory {
  font-weight: bold;
}

.card-header {
  h2 {
    font-size: 1.2rem;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.colourset-selection {
}

.colour-palette {
  width: 100%;
  flex-wrap: wrap;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.publish-status-container {
  width: 96.5%;
  margin-left: auto;
  margin-right: auto;
}
</style>
