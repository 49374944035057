<script>
import { Pie } from 'vue-chartjs'
import 'chartjs-plugin-labels'
import DashboardUtils from '@/modules/dashboard/utils'
import CurrencyStringMixin from '@/modules/insight/mixins/CurrencyStringMixin'

export default {
  name: 'pie-chart',
  extends: Pie,
  mixins: [CurrencyStringMixin],
  data: () => ({
    aggregateType: 'COUNT', // default for now
    formattedChartData: [],
    formattedChartOptions: []
  }),
  props: {
    rawChartData: {
      type: Object,
      // skeleton of a rawChartData object
      default: () => ({
        id: -1,
        title: '',
        chart_type: '',
        label_type: '',
        colour_set: '',
        c_datasets: [],
        generated_details: {
          field_label: '',
          query_details: [],
          labels: [],
          values: []
        }
      })
    },
    colourSet: { // optional
      type: String,
      default: ''
    }
  },
  mounted () {
    this.formattedChartData = this.getFormattedChartData()
    this.formattedChartOptions = this.getFormattedChartOptions()
    this.renderPieChart()
  },
  computed: {
    computedShouldDisplayLegend () {
      return this.shouldDisplayLegend(this.rawChartData)
    },
    pluginLabels () {
      if (this.computedShouldDisplayLegend) {
        return [{
          render: 'value',
          position: 'border'
        },
        {
          render: 'percentage',
          precision: 1,
          position: 'outside',
          textMargin: 4
        }]
      } else {
        return []
      }
    },
    computedColourSet () {
      return this.getColourSet(this.colourSet, this.rawChartData.colour_set)
    }
  },
  methods: {
    shouldDisplayLegend: DashboardUtils.shouldDisplayLegend,
    getColourSet: DashboardUtils.getColourSet,
    renderPieChart () {
      this.renderChart(this.formattedChartData, this.formattedChartOptions)
    },
    /**
     * Returns the formatted labels of the chart
     * @param {Array} labels - List of unformatted labels
     * @param {String} chartDataFieldFormat - Format of field e.g. Currency
     * @returns {Array} List of formatted labels
     */
    getFormattedGeneratedDetailsLabels (labels, chartDataFieldFormat) {
      if (chartDataFieldFormat === this.$INSIGHT('VALIDATION_FORMAT').CURRENCY) {
        // Format labels to currency format
        const formattedLabels = []
        labels.forEach(label => formattedLabels.push(this.currencyString(label)))
        return formattedLabels
      } else {
        return labels
      }
    },
    getFormattedChartData () {
      return {
        labels: this.getFormattedGeneratedDetailsLabels(this.rawChartData.generated_details.labels, this.rawChartData.generated_details.field_format),
        datasets: [
          {
            // TODO: Check label rendering
            data: this.rawChartData.generated_details.values
          }
        ]
      }
    },
    getFormattedChartOptions () {
      // TODO: Allow customisation in options
      return {
        plugins: {
          colorschemes: {
            scheme: this.computedColourSet
          },
          labels: this.pluginLabels
        },
        legend: {
          display: this.computedShouldDisplayLegend,
          labels: {
            generateLabels: function (chart) {
              // overriding source code to generate a shortened label
              const data = chart.data
              if (data.labels.length && data.datasets.length) {
                return data.labels.map((label, i) => {
                  const meta = chart.getDatasetMeta(0)
                  const style = meta.controller.getStyle(i)
                  const formattedLabel = DashboardUtils.shortenXAxesLabel(label) // shorten label
                  return {
                    text: formattedLabel,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    hidden: isNaN(data.datasets[0].data[i]) || meta.data[i].hidden,
                    // Extra data used for toggling the correct item
                    index: i
                  }
                })
              }
              return []
            }
          }
        },
        tooltips: {
          callbacks: {
            title: /* istanbul ignore next */ (toolTipItemArray, data) => DashboardUtils.tooltipTitleCallback(toolTipItemArray, data), // unable to test this
            label: /* istanbul ignore next */ (toolTipItem, data) => DashboardUtils.tooltipLabelCallback(toolTipItem, data) // unable to test this
          }
        }
      }
    }
  },
  watch: {
    computedColourSet: {
      handler () {
        if (this.$data._chart) {
          this.formattedChartOptions = this.getFormattedChartOptions() // update colour scheme
          this.$data._chart.destroy()
          this.renderPieChart()
        }
      }
    }
  }
}
</script>
