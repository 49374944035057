<template>
  <div class="wizard">
    <b-container>
      <b-row v-if="isWizard">
        <b-col>
          <div class="steps" v-data-cy="'steps'">
            <div :id="`step-${step.number}`" v-data-cy:eval="`step-${step.number}`"
              class="step" :class="{ [state(step.number)]: true }"
              v-for="step of steps" :key="step.number"
              @click="jumpTo(step.number)"
            >
              <div class="number">
                <!-- TODO Switch to svg -->
                <span v-if="state(step.number) !== 'complete'">{{ step.number }}</span>
              </div>
              <div class="name">
                {{ step.name }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="justify-content-center">
        <b-col>
          <slot/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'WizardContainer',
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    steps: [
      {
        number: 1,
        name: Vue.prototype.$CONFIG('STEPS').MODULE_MANAGEMENT
      },
      {
        number: 2,
        name: Vue.prototype.$CONFIG('STEPS').AUTHENTICATION
      },
      {
        number: 3,
        name: Vue.prototype.$CONFIG('STEPS').DATABASE_CONNECTION
      },
      {
        number: 4,
        name: Vue.prototype.$CONFIG('STEPS').DATABASE_MAPPING
      },
      {
        number: 5,
        name: Vue.prototype.$CONFIG('STEPS').AUDIT_DATABASE_CONNECTION
      },
      {
        number: 6,
        name: Vue.prototype.$CONFIG('STEPS').SETUP_COMPLETE
      }
    ]
  }),
  computed: {
    isWizard () {
      return this.$route.meta.wizard !== undefined
    }
  },
  methods: {
    state (number) {
      if (number < this.step) return 'complete'
      if (number === this.step) return 'inprogress'
      return 'incomplete'
    },
    jumpTo (number) {
      if (this.state(number) !== 'complete') return

      this.$router.push({
        name: 'Wizard Step ' + number
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wizard {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .dashboard {
    .wizard {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  $circle: 60px;
  $gap: 80px;

  .steps {
    display: flex;
    justify-content: center;

    .step {
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0 0 2% 0;
      width: $circle + $gap;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;

      &:before {
        content: "";
        width: 100%;
        height: 3px;
        background: #FFF;
        display: block;
        position: absolute;
        top: $circle / 2;
        left: 0;
        z-index: 10;
      }

      .number {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFF;
        border-radius: 100%;
        height: $circle;
        width: $circle;
        margin-bottom: 10px;
        z-index: 100;
        transition: all 300ms ease-in;
        align-self: center;
      }

      .name {
        color: #FFF;
        text-align: center;
        font-weight: 300;
        width: 100%;
        flex-grow: 1;
      }

      &:first-of-type {
        &:before {
          left: $gap / 2;
          width: calc(100% - #{$gap / 2});
        }
      }

      &:last-of-type {
        &:before {
          width: calc(100% - #{$gap / 2});
        }
      }

      &.incomplete {
        &:before {
          background: #545459;
        }

        .number {
          background: #545459;
          color: #FFF;
        }
      }

      &.inprogress {
        &:before {
          background: #128491;
        }

        .number {
          background: #128491;
          color: #FFF;
        }
      }

      &.complete {
        cursor: pointer;

        &:before {
          background: #28a745;
        }

        .number {
          background: #28a745;
          color: #FFF;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='23' viewBox='0 0 12 16'%3E%3Cpath fill-rule='evenodd' fill='%23FFFFFF' d='M12 5l-8 8-4-4 1.5-1.5L4 10l6.5-6.5L12 5z'/%3E%3C/svg%3E");
        }
      }
    }
  }
</style>
